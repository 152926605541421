import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./QuantityControl.scss"

const QuantityControl = ({ onChange, amount = 1, enableChangeAmount = true }) => {
  // console.log("enableChangeAmount", enableChangeAmount)
  const quantityController = (direction) => {
    if (direction === "down") {
      onChange(amount - 1)
    }

    if (direction === "up") {
      onChange(amount + 1)
    }
  }

  return (
    <div className="quantity-control ">
      {enableChangeAmount ? <div className="quantity-control--left">
        <FontAwesomeIcon icon={amount > 1 ? "minus" : "trash"} onClick={() => quantityController("down")} />
      </div> : ""}

      <div className="quantity-control--center">{amount}</div>
      {enableChangeAmount ? <div className="quantity-control--right">
        <FontAwesomeIcon icon={"plus"} onClick={() => quantityController("up")} />
      </div> : ""}

    </div>
  )
}

export default QuantityControl

import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import CFButton from "../../../components/CFButton/CFButton"
import CFInput from "../../../components/CFInput/CFInput"
import CFSelect from "../../../components/CFSelect/CFSelect"
import "./ModalFinalAddress.scss"
import { onMessageDialog, capitalize, formatBoolean } from "../../../helpers/GeneralTools"
import SettingsContext from "../../../contexts/SettingsContext"
import { getCitiesByState, getStates } from "../../../helpers/AddressessTools"
import AddressController from "../../../controllers/AddressController"

const ModalFinalAddress = ({ status, address, onClose, onConfirmAddress, origin = "", editNeighbourhood }) => {
  const { neighbourhoods, settings } = React.useContext(SettingsContext)
  const [finalAddress, setFinalAddress] = React.useState({})
  const [enableEditNeighbourhood, setEnableEditNeighbourhood] = React.useState(false)
  const [newNeighbourhood, setNewNeighbourhood] = React.useState(false)
  const [validNeighbourhood, setValidNeighbourhood] = React.useState(true)
  const [filteredNeighbourhoods, setFilteredNeighbourhoods] = React.useState([])
  const [title, setTitle] = React.useState("Confirme o endereço")
  const [states, setStates] = React.useState([])
  const [selectedState, setSelectedState] = React.useState({})
  const [cities, setCities] = React.useState([])
  const [selectedCity, setSelectedCity] = React.useState({})

  React.useEffect(() => {
    getAllStates()

    if (status) {
      if (editNeighbourhood) {
        setFinalAddress({
          ...address,
          neighbourhood: { ...address.neighbourhood, uuid_neighbourhood: null, nm_neighbourhood: null },
        })
      } else {
        setFinalAddress(address)
      }
      const deliveryFeeType = settings?.display_mode_fee
      if (deliveryFeeType === "neighborhood" && !formatBoolean(settings?.enable_delivery_areas)) {
        if (!!address.customer_address) {
          const filteredNeighbourhoods = neighbourhoods.filter(({ city }) => city?.uuid_city === address?.neighbourhood?.city?.uuid_city)
          if (filteredNeighbourhoods.length === 0) {
            onMessageDialog("info")
              .fire({
                title: "Infelizmente não entregamos em nenhum bairro da cidade selecionada.",
                icon: "info",
                timer: 4000,
                showCancelButton: false,
                showConfirmButton: false,
              })
              .then()
          }
          setFilteredNeighbourhoods(filteredNeighbourhoods)
          if ((!!address.neighbourhood && !address.neighbourhood.uuid_neighbourhood) || editNeighbourhood) {
            setEnableEditNeighbourhood(true)
          } else {
            setEnableEditNeighbourhood(false)
          }
        } else {
          setFinalAddress({
            customer_address: {
              geo_coordinates: [address.latitude, address.longitude],
            },

            street_type: { id_street_type: 3, nm_type: "Rua" },
          })

          setTitle("Inserir localização manualmente")
          setNewNeighbourhood(true)
          getAllStates()
        }
      } else {
        setFilteredNeighbourhoods(neighbourhoods)
        if ((!!address.neighbourhood && !address.neighbourhood.uuid_neighbourhood) || editNeighbourhood) {
          setEnableEditNeighbourhood(true)
        } else {
          setEnableEditNeighbourhood(false)
          if (!!address.customer_address) {
            if (editNeighbourhood) {
              setFinalAddress({
                ...address,
                neighbourhood: { ...address.neighbourhood, uuid_neighbourhood: null, nm_neighbourhood: null },
              })
            } else {
              setFinalAddress(address)
            }

            const deliveryFeeType = settings?.display_mode_fee

            if (deliveryFeeType === "neighborhood") {
              const filteredNeighbourhoods = neighbourhoods.filter(({ city }) => city?.uuid_city === address?.neighbourhood?.city?.uuid_city)
              if (filteredNeighbourhoods.length === 0) {
                onMessageDialog("info")
                  .fire({
                    title: "Infelizmente não entregamos em nenhum bairro da cidade selecionada.",
                    icon: "info",
                    timer: 4000,
                    showCancelButton: false,
                    showConfirmButton: false,
                  })
                  .then()
              }
              setFilteredNeighbourhoods(filteredNeighbourhoods)
              if ((!!address.neighbourhood && !address.neighbourhood.uuid_neighbourhood) || editNeighbourhood) {
                setEnableEditNeighbourhood(true)
              } else {
                setEnableEditNeighbourhood(false)
              }
            } else {
              setFilteredNeighbourhoods(neighbourhoods)
              if ((!!address.neighbourhood && !address.neighbourhood.uuid_neighbourhood) || editNeighbourhood) {
                setEnableEditNeighbourhood(true)
              } else {
                setEnableEditNeighbourhood(false)
                if (!!address.customer_address) {
                  setFinalAddress(address)
                } else {
                  setFinalAddress({
                    customer_address: {
                      geo_coordinates: [address.latitude, address.longitude],
                    },
                    street_type: { id_street_type: 3, nm_type: "Rua" },
                  })
                }

                setTitle("Confirme o endereço")
                const deliveryFeeType = settings?.display_mode_fee
                if (deliveryFeeType === "neighborhood") {
                  const filteredNeighbourhoods = neighbourhoods.filter(({ city }) => city?.uuid_city === address?.neighbourhood?.city?.uuid_city)
                  if (filteredNeighbourhoods.length === 0) {
                    onMessageDialog("info")
                      .fire({
                        title: "Infelizmente não entregamos em nenhum bairro da cidade selecionada.",
                        icon: "info",
                        timer: 4000,
                        showCancelButton: false,
                        showConfirmButton: false,
                      })
                      .then()
                  }
                  setFilteredNeighbourhoods(filteredNeighbourhoods)
                  if (!!address.neighbourhood && !address.neighbourhood.uuid_neighbourhood) {
                    setEnableEditNeighbourhood(true)
                  } else {
                    setEnableEditNeighbourhood(false)
                  }
                } else {
                  setFilteredNeighbourhoods(neighbourhoods)
                  if (!!address.neighbourhood && !address.neighbourhood.uuid_neighbourhood) {
                    setEnableEditNeighbourhood(true)
                  } else {
                    setEnableEditNeighbourhood(false)
                  }
                }
              }
            }
          } else {
            setFinalAddress({
              customer_address: {
                geo_coordinates: [address.latitude, address.longitude],
              },

              street_type: { id_street_type: 3, nm_type: "Rua" },
            })

            setTitle("Inserir localização manualmente")
            setNewNeighbourhood(true)
            getAllStates()
          }
        }
      }
    } else {
      setStates([])
      setSelectedState({})
      setCities([])
      setSelectedCity({})
    }
  }, [status])

  const onChangeStreetName = (nm_street) => {
    setFinalAddress({ ...finalAddress, nm_street })
  }

  const onChangeNumber = (num_address) => {
    setFinalAddress({ ...finalAddress, customer_address: { ...finalAddress.customer_address, num_address } })
  }

  const onChangeComplement = (ds_address_additional_info) => {
    setFinalAddress({ ...finalAddress, customer_address: { ...finalAddress.customer_address, ds_address_additional_info } })
  }

  const onChangeReference = (ds_observation) => {
    setFinalAddress({ ...finalAddress, customer_address: { ...finalAddress.customer_address, ds_observation } })
  }

  const onChangeNeighbourhood = (neighbourhood) => {
    setFinalAddress({ ...finalAddress, neighbourhood: { ...finalAddress.neighbourhood, ...neighbourhood } })
  }

  const onChangeNeighbourhoodName = (neighbourhoodName) => {
    setFinalAddress({
      ...finalAddress,
      neighbourhood: {
        ...finalAddress.neighbourhood,
        nm_neighbourhood: neighbourhoodName,
        id_neighbourhood: null,
        uuid_neighbourhood: null,
        city: selectedCity,
      },
    })
  }

  const onChangeStateSelected = (state) => {
    setSelectedState(state)

    getAllCities(state.uuid_state)
  }

  const onChangeCitySelected = async (city) => {
    setSelectedCity(city)
    setFinalAddress({ ...finalAddress, neighbourhood: { ...finalAddress.neighbourhood, city } })

    const responseNeighbourhood = await AddressController.neighbourhoodByCity(city.uuid_city)
    const neighbourhoodsResponse =
      responseNeighbourhood.status === 200 ? responseNeighbourhood.data.filter(({ nm_neighbourhood }) => nm_neighbourhood) : []

    let neighbourhoodsWithLabel = []

    neighbourhoodsResponse.forEach((neighbourhood) => {
      neighbourhoodsWithLabel = [...neighbourhoodsWithLabel, { ...neighbourhood, label: neighbourhood.nm_neighbourhood }]
    })

    setFilteredNeighbourhoods(neighbourhoodsWithLabel)
  }

  const onValidAddress = () => {
    if (!finalAddress.customer_address.num_address && !finalAddress.customer_address.ds_address_additional_info) {
      onMessageDialog("info")
        .fire({
          title: "Preencha um número ou um complemento",
          icon: "info",
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        })
        .then()
    } else if (!finalAddress.nm_street) {
      onMessageDialog("info")
        .fire({
          title: "Preencha o logradouro",
          icon: "info",
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        })
        .then()
    } else if (
      (settings?.display_mode_fee === "neighborhood" &&
        finalAddress?.neighbourhood?.nm_neighbourhood &&
        finalAddress?.neighbourhood?.uuid_neighbourhood) ||
      (["km", "kmdriven"].includes(settings?.display_mode_fee) && finalAddress?.neighbourhood?.nm_neighbourhood)
    ) {
      onConfirmAddress(finalAddress)
    } else {
      onMessageDialog("info")
        .fire({
          title: "Selecione um Bairro",
          icon: "info",
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        })
        .then()

      setValidNeighbourhood(false)
    }
  }

  const getAllStates = async () => {
    let statesWithLabel = []

    const states = await getStates()

    states.forEach((state) => {
      if (!!state.id_state) {
        statesWithLabel = [...statesWithLabel, { ...state, label: state.ds_acronym }]
      }
    })

    setStates(statesWithLabel)
  }

  const getAllCities = async (uuid) => {
    if (!!uuid) {
      let citiesWithLabel = []

      const cities = await getCitiesByState(uuid)

      await cities.forEach((city) => {
        if (!!city.id_city) {
          citiesWithLabel = [...citiesWithLabel, { ...city, label: city.nm_city }]
        }
      })

      setCities(citiesWithLabel)
    }
  }

  return (
    <Modal className="modal-final-address" isOpen={status} toggle={onClose} backdrop={"static"} fade={false} keyboard={false}>
      <ModalHeader className="modal-final-address--header">
        <Label>{title}</Label>
        <p>Veja se seu endereço está correto, caso não esteja, edite e ajuste as informações</p>
      </ModalHeader>
      <ModalBody className="modal-final-address--body">
        <div className="modal-final-address--body--input">
          <label>Digite o nome da sua rua</label>
          <CFInput
            autoFocus
            placeholder={"Logradouro"}
            onChange={(event) => onChangeStreetName(event.target.value)}
            value={finalAddress?.nm_street || ""}
          />
        </div>

        <div className="modal-final-address--body--number-complement">
          <div className="modal-final-address--body--input">
            <label>Numero da casa</label>
            <CFInput
              placeholder={"Número"}
              onChange={(event) => onChangeNumber(event.target.value)}
              value={finalAddress?.customer_address?.num_address || ""}
            />
          </div>

          <div className="modal-final-address--body--input">
            <label>Seu complemento</label>
            <CFInput
              placeholder={"Complemento"}
              onChange={(event) => onChangeComplement(event.target.value || "")}
              value={finalAddress?.customer_address?.ds_address_additional_info || ""}
            />
          </div>
        </div>
        <div className="modal-final-address--body--input">
          <label>Possúi ponto de referência?</label>
          <CFInput
            placeholder={"Ponto de Referência"}
            style={{ textAlign: "left", paddingLeft: 20 }}
            onChange={(event) => onChangeReference(event.target.value || "")}
            value={finalAddress?.customer_address?.ds_observation || ""}
          />
        </div>

        <div className="modal-final-address--body--neighbourhood">
          {origin === "fromMap" ? (
            ["km", "kmdriven"].includes(settings?.display_mode_fee) || formatBoolean(settings?.enable_delivery_areas) ? (
              <>
                <CFSelect
                  style={{ textAlign: "left", paddingLeft: 20, display: !enableEditNeighbourhood ? "block" : "none" }}
                  options={filteredNeighbourhoods}
                  label="nm_neighbourhood"
                  value="uuid_neighbourhood"
                  onChange={onChangeNeighbourhood}
                  selected={finalAddress?.neighbourhood?.uuid_neighbourhood || ""}
                  valid={!!finalAddress?.neighbourhood?.nm_neighbourhood || validNeighbourhood}
                  disabled={!Object.keys(selectedCity).length > 0}
                  type={"neighbourhood"}
                />
                <CFInput
                  placeholder={"Nome do Bairro"}
                  style={{ textAlign: "left", paddingLeft: 20, height: "40px", display: enableEditNeighbourhood ? "block" : "none" }}
                  onChange={(event) => onChangeNeighbourhoodName(event.target.value || "")}
                  value={capitalize(finalAddress?.neighbourhood?.nm_neighbourhood || "")}
                />
                <Label onClick={() => setEnableEditNeighbourhood(!enableEditNeighbourhood)}>
                  <FontAwesomeIcon icon={!enableEditNeighbourhood ? "plus" : "times"} />
                </Label>
              </>
            ) : (
              <>
                <CFSelect
                  style={{ textAlign: "left", paddingLeft: 20 }}
                  options={neighbourhoods.filter(({ city }) => city.uuid_city === selectedCity.uuid_city)}
                  label="nm_neighbourhood"
                  value="uuid_neighbourhood"
                  onChange={onChangeNeighbourhood}
                  selected={finalAddress?.neighbourhood?.uuid_neighbourhood || ""}
                  valid={!!finalAddress?.neighbourhood?.nm_neighbourhood || validNeighbourhood}
                  disabled={!Object.keys(selectedCity).length > 0}
                  type={"neighbourhood"}
                />
              </>
            )
          ) : !newNeighbourhood ? (
            <CFSelect
              style={{ textAlign: "left", paddingLeft: 20 }}
              options={filteredNeighbourhoods}
              label="nm_neighbourhood"
              value="uuid_neighbourhood"
              onChange={onChangeNeighbourhood}
              selected={finalAddress?.neighbourhood?.uuid_neighbourhood || ""}
              disabled={!enableEditNeighbourhood}
              valid={!!finalAddress?.neighbourhood?.nm_neighbourhood || validNeighbourhood}
              type={"neighbourhood"}
            />
          ) : (
            <CFInput
              placeholder={"Nome do Bairro"}
              style={{ textAlign: "left", paddingLeft: 20, height: "40px" }}
              onChange={(event) => onChangeNeighbourhoodName(event.target.value || "")}
              value={capitalize(finalAddress?.neighbourhood?.nm_neighbourhood || "")}
            />
          )}
          {origin === "fromMap" ? (
            <></>
          ) : (
            <Label onClick={() => setEnableEditNeighbourhood(!enableEditNeighbourhood)}>
              <FontAwesomeIcon icon={!enableEditNeighbourhood ? "pen" : "check"} />
            </Label>
          )}
          {["km", "kmdriven"].includes(settings?.display_mode_fee) && !origin === "fromMap" ? (
            <Label onClick={() => setNewNeighbourhood(!newNeighbourhood)}>
              <FontAwesomeIcon icon={!newNeighbourhood ? "plus" : "times"} />
            </Label>
          ) : (
            ""
          )}
        </div>
        <div className="modal-final-address--body--city-state">
          {origin === "fromMap" ? (
            <>
              <CFSelect
                style={{ textAlign: "left", paddingLeft: 20 }}
                options={cities}
                label="nm_city"
                value="uuid_city"
                onChange={onChangeCitySelected}
                selected={selectedCity?.uuid_city || ""}
                disabled={!cities.length > 0}
                type={"city"}
              />
              <CFSelect
                style={{ textAlign: "left", paddingLeft: 20 }}
                options={states}
                label="nm_state"
                value="uuid_state"
                onChange={onChangeStateSelected}
                selected={selectedState?.uuid_state || ""}
                type={"state"}
              />
            </>
          ) : (
            <>
              <CFInput
                disabled={true}
                placeholder={"Cidade"}
                style={{ textAlign: "left", paddingLeft: 40 }}
                value={finalAddress?.neighbourhood?.city?.nm_city || ""}
              />
              <CFInput
                disabled={true}
                placeholder={"Estado"}
                style={{ textAlign: "left", paddingLeft: 40 }}
                value={finalAddress?.neighbourhood?.city?.state?.ds_acronym || ""}
              />
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="modal-final-address--footer">
        <CFButton title="Voltar" onClick={onClose} outline={true} />
        <CFButton title="Confirmar" onClick={onValidAddress} />
      </ModalFooter>
    </Modal>
  )
}

export default ModalFinalAddress

import React from "react"
//External Libs
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//Components
//Contexts
import CompanyContext from "../../contexts/CompanyContext"

import "./DeliveryTypeSelectorTakeOut.scss"
import { formatBoolean } from "../../helpers/GeneralTools"
import SettingsContext from "../../contexts/SettingsContext"

const DeliveryTypeSelectorTakeOut = ({ consumeInEstablishment, setConsumeInEstablishment }) => {
  const { company } = React.useContext(CompanyContext)
  const { settings } = React.useContext(SettingsContext)

  const companyGeolocation = React.useRef(null)

  const companyAddress = company?.company_address?.find(({ st_primary }) => st_primary === true)

  if (!!companyAddress) {
    companyGeolocation.current = {
      latitude: companyAddress.geo_coordinates[0],
      longitude: companyAddress.geo_coordinates[1],
    }
  }

  return (
    <div className="delivery-type-selector-take-out">
      {/*<div className="delivery-type-selector-take-out--header">*/}
      {/*  <span>Retirar no restaurante</span>*/}
      {/*  <CFCheckButton status={orderToTakeOut} onClick={(status) => setOrderToTakeOut(status)} />*/}
      {/*</div>*/}

      {(() => {
        const modalities = company.company_modality.filter((modality) => {
          return modality.fk_id_modality !== 1 && modality.fk_id_modality !== 4
        })

        if (modalities.length > 0) {
          return (
            <div className="delivery-type-selector-take-out--border">
              <div className="delivery-type-selector-take-out--footer">
                <div className="delivery-type-selector-take-out--footer--buttons">
                  <button
                    onClick={() => {
                      setConsumeInEstablishment(false)
                    }}
                    className={`delivery-type-selector-take-out--footer--buttons--button ${consumeInEstablishment ? "" : "selected"}`}
                  >
                    <div className="delivery-type-selector-take-out--footer--buttons--button--left">
                      <FontAwesomeIcon icon="location-dot" />
                    </div>
                    <div className="delivery-type-selector-take-out--footer--buttons--button--center">
                      <h1>Retirar no estabelecimento</h1>
                      <p>Seja avisado quando seu pedido estiver pronto, só chegar e retirar sua refeição.</p>
                    </div>
                    <div className="delivery-type-selector-take-out--footer--buttons--button--right" />
                  </button>
                  {formatBoolean(settings?.show_local_consumation) && (
                    <button
                      onClick={() => {
                        setConsumeInEstablishment(true)
                      }}
                      className={`delivery-type-selector-take-out--footer--buttons--button ${consumeInEstablishment ? "selected" : ""}`}
                    >
                      <div className="delivery-type-selector-take-out--footer--buttons--button--left">
                        <FontAwesomeIcon icon="cutlery" />
                      </div>
                      <div className="delivery-type-selector-take-out--footer--buttons--button--center">
                        <h1>Consumir no local</h1>
                        <p>Seja avisado quando seu pedido estiver pronto, só chegar e aproveitar sua refeição.</p>
                      </div>
                      <div className="delivery-type-selector-take-out--footer--buttons--button--right" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          )
        }
      })()}

      {!!companyAddress ? (
        <div className="delivery-type-selector-take-out--body">
          <div className="delivery-type-selector-take-out--body--left">
            <FontAwesomeIcon icon={"location-dot"} />
          </div>
          <div className="delivery-type-selector-take-out--body--center">
            <span className="delivery-type-selector-take-out--body--center--title">Retirar em</span>
            <span>
              {companyAddress?.street?.nm_street}, {companyAddress?.num_address} - {companyAddress?.street?.neighbourhood?.city?.nm_city}
            </span>
          </div>
          {!!companyGeolocation.current ? (
            <div
              className="delivery-type-selector-take-out--body--right"
              onClick={() => {
                window.open(
                  `http://www.google.com/maps/place/${companyGeolocation.current.latitude},${companyGeolocation.current.longitude}`,
                  "_blank",
                  "location=yes"
                )
              }}
            >
              <span>Abrir no mapa</span>
              <FontAwesomeIcon icon={"chevron-right"} />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default DeliveryTypeSelectorTakeOut

import React from "react"
//External libs
import CurrencyInput from "react-currency-input"
//Helpers
import GeneralTools, { formatMoney, getUrlAWSFile } from "../../helpers/GeneralTools"
import { getDiscountOrder, getTotalOrder } from "../../helpers/OrderTools"
//Contexts
import OrderContext from "../../contexts/OrderContext"
import SettingsContext from "../../contexts/SettingsContext"
import UserContext from "../../contexts/UserContext"
//Images
//Styles
import "./PaymentCategoryItems.scss"

const PaymentCategoryItems = ({
  data = [],
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  showPaymentChange,
  paymentChange,
  setPaymentChange,
  index,
  onlinePayment,
  setPaymentType,
  onCreatePayment,
}) => {
  const { order } = React.useContext(OrderContext)
  const { userSelectedAddress } = React.useContext(UserContext)
  const { modality, settings } = React.useContext(SettingsContext)
  const [, , benefit] = getTotalOrder(order, userSelectedAddress?.deliveryFee)

  const [selectedPayment, setSelectedPayment] = React.useState(null)

  const savedCards = [
    {
      id_card: 1,
      nm_card_brand: "elo",
      nro_card: "6527 3472 4424 9973",
      nm_owner: "Lucas Varela de Melo",
      cpf_owner: "149.420.699-47",
    },
    {
      id_card: 2,
      nm_card_brand: "visa",
      nro_card: "2876 82462 9375 2884",
      nm_owner: "Lucas Varela de Melo",
      cpf_owner: "149.420.699-47",
    },
    {
      id_card: 3,
      nm_card_brand: "mastercard",
      nro_card: "3306 7763 8975 3763",
      nm_owner: "Lucas Varela de Melo",
      cpf_owner: "149.420.699-47",
    },
  ]

  return (
    <div className="payment-category-items" key={index || Math.random().toString(16)}>
      {!onlinePayment && (
        <div className="payment-category-items--category">
          {data.subCategories
            .filter((subCategory) => subCategory.nm_subcategory_payment_methods.toLowerCase() !== "pagamento site")
            .map((subcategory, index) => {
              if (subcategory.subCategoryPayments.length > 0) {
                return (
                  <div key={index} className="payment-category-items--category">
                    <span key={index} className="payment-category-items--category--title">
                      {subcategory.nm_subcategory_payment_methods}
                    </span>

                    {subcategory.subCategoryPayments.length > 0
                      ? subcategory.subCategoryPayments.map((payment, index) => {
                          return (
                            <div key={index} style={{ width: "100%" }}>
                              <div
                                key={index}
                                className={
                                  "payment-category-items--category--payment-method" +
                                  (selectedPaymentMethod.id_payment_method === payment.id_payment_method
                                    ? " payment-category-items--category--payment-method--selected"
                                    : "")
                                }
                                onClick={() => setSelectedPaymentMethod(payment)}
                              >
                                <img alt="" src={getUrlAWSFile(payment.url_image)} />
                                <span>{payment.nm_payment_method}</span>
                              </div>

                              {showPaymentChange && payment?.nm_payment_method.toLowerCase() === "dinheiro" ? (
                                <div className={"payment-category-items--category--payment-method--change"}>
                                  <span>
                                    É necessário troco? Total a pagar{" "}
                                    {formatMoney(
                                      order.subTotal +
                                        (![3, 2].includes(modality) ? userSelectedAddress?.deliveryFee || 0 : 0) -
                                        benefit -
                                        getDiscountOrder(
                                          { ...order },
                                          order.subTotal,
                                          ![3, 2].includes(modality) ? userSelectedAddress?.deliveryFee || 0 : 0,
                                          settings,
                                          modality
                                        ) -
                                        // (order?.payments[1]?.val_payment > 0 ? order?.payments[1]?.val_payment : 0)
                                        (!!order.payments.find(({ nm_payment_method }) => nm_payment_method.toLowerCase() === "cashback")
                                          ? order.payments.find(({ nm_payment_method }) => nm_payment_method.toLowerCase() === "cashback").val_payment
                                          : 0)
                                    )}
                                  </span>
                                  <CurrencyInput
                                    prefix="R$ "
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    allowEmpty={true}
                                    className="payment-category-items--category--payment-method--change--input"
                                    inputType="text"
                                    autoFocus
                                    placeholder="Informe o valor"
                                    value={paymentChange > 0 ? GeneralTools.formatMoney(paymentChange) : null}
                                    onChangeEvent={(event) =>
                                      setPaymentChange(GeneralTools.parseFloat(event.target.value.substring("R$ ".length).replace(".", "")))
                                    }
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          )
                        })
                      : ""}
                  </div>
                )
              }
            })}
        </div>
      )}

      {onlinePayment && (
        <div className="payment-category-items--category">
          {data.subCategories
            .filter((subCategory) => subCategory.nm_subcategory_payment_methods.toLowerCase() === "pagamento site")
            .map((subcategory, index) => {
              if (subcategory.subCategoryPayments.length > 0) {
                return (
                  <div key={index} className="payment-category-items--category">
                    <span key={index} className="payment-category-items--category--title">
                      {subcategory.nm_subcategory_payment_methods}
                    </span>

                    {subcategory.subCategoryPayments.length > 0
                      ? subcategory.subCategoryPayments.map((payment, index) => {
                          return (
                            <div key={index} style={{ width: "100%" }}>
                              <div
                                key={index}
                                className={
                                  "payment-category-items--category--payment-method" +
                                  (selectedPaymentMethod.id_payment_method === payment.id_payment_method
                                    ? " payment-category-items--category--payment-method--selected"
                                    : "")
                                }
                                onClick={() => setSelectedPaymentMethod(payment)}
                              >
                                <img alt="" src={getUrlAWSFile(payment.url_image)} />
                                <span>{payment.nm_payment_method}</span>
                              </div>

                              {showPaymentChange && payment?.nm_payment_method.toLowerCase() === "dinheiro" ? (
                                <div className={"payment-category-items--category--payment-method--change"}>
                                  <span>
                                    É necessário troco? Total a pagar{" "}
                                    {formatMoney(
                                      order.subTotal +
                                        (![3, 2].includes(modality) ? userSelectedAddress?.deliveryFee || 0 : 0) -
                                        benefit -
                                        getDiscountOrder(
                                          { ...order },
                                          order.subTotal,
                                          ![3, 2].includes(modality) ? userSelectedAddress?.deliveryFee || 0 : 0,
                                          settings,
                                          modality
                                        ) -
                                        // (order?.payments[1]?.val_payment > 0 ? order?.payments[1]?.val_payment : 0)
                                        (!!order.payments.find(({ nm_payment_method }) => nm_payment_method.toLowerCase() === "cashback")
                                          ? order.payments.find(({ nm_payment_method }) => nm_payment_method.toLowerCase() === "cashback").val_payment
                                          : 0)
                                    )}
                                  </span>
                                  <CurrencyInput
                                    prefix="R$ "
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    allowEmpty={true}
                                    className="payment-category-items--category--payment-method--change--input"
                                    inputType="text"
                                    autoFocus
                                    placeholder="Informe o valor"
                                    value={paymentChange > 0 ? GeneralTools.formatMoney(paymentChange) : null}
                                    onChangeEvent={(event) =>
                                      setPaymentChange(GeneralTools.parseFloat(event.target.value.substring("R$ ".length).replace(".", "")))
                                    }
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          )
                        })
                      : ""}
                  </div>
                )
              }
            })}
        </div>
      )}
    </div>
  )
}

export default PaymentCategoryItems

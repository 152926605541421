import React from "react"
//External Libs
import MaskedInput from "react-text-mask"

import { Input, Label, Spinner, Modal, ModalHeader, ModalFooter } from "reactstrap"

//Components
import CFButton from "../../components/CFButton/CFButton"
import { encrypt } from "../../helpers/StorageTools"
import FullContentLoading from "../../components/FullContentLoading"
import GeneralTools, { formatBoolean, formatPhone, getUrlAWSManagerFile, onMessageDialog } from "../../helpers/GeneralTools"

//Controllers
import UserController from "../../controllers/UserController"
import CustomerController from "../../controllers/CustomerController"

//Contexts
import CompanyContext from "../../contexts/CompanyContext"
import SettingsContext from "../../contexts/SettingsContext"

// Styles
import "./ModalSignIn.scss"

const ModalSignIn = ({ status, onSignIn, onClose, recoverySettings }) => {
  const { company } = React.useContext(CompanyContext)
  const { settings } = React.useContext(SettingsContext)
  const [loginType, setLoginType] = React.useState("input")
  const [username, setUsername] = React.useState("")
  const [validUsername, setValidUsername] = React.useState(null)
  const [password, setPassword] = React.useState(null)
  const [confirmationPassword, setConfirmationPassword] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [loadingRegister, setLoadingRegister] = React.useState(false)
  const [customerName, setCustomerName] = React.useState("")
  const [havePhoneLogin, setHavePhoneLogin] = React.useState(false)
  const [actionUpdate, setActionUpdate] = React.useState(false)
  const [footerButton, setFooterButton] = React.useState(null)
  const [customerUid, setCustomerUid] = React.useState("")
  const [customerHasEmail, setCustomerHasEmail] = React.useState(false)
  const finalizingCustomerRegisterRef = React.useRef(false)

  const [validations, setValidations] = React.useState({
    username: false,
    customerName: false,
    phone: false,
    email: false,
    password: false,
    passwordConfirmation: false,
  })

  const handleToggleClick = (event) => {
    if (event.target.id === "id-modal-signin") onClose()
  }

  React.useEffect(() => {
    setFooterButton({
      text: "Continuar",
      action: "verify",
    })
  }, [])

  React.useEffect(() => {
    if (username.length > 0) {
      if (username.indexOf("@") !== -1 && username.indexOf(".") !== -1 && username.indexOf("@") < username.lastIndexOf(".")) {
        setLoginType("email")
      } else if (!isNaN(Number(username)) || (loginType === "phone" && !isNaN(Number(formatPhone(username, false))))) {
        setLoginType("phone")
      } else {
        setLoginType("input")
      }
    } else if (username.length === 0) {
      clearModal()
      setLoading(false)
    }
  }, [username])

  React.useEffect(() => {
    if (!status) {
      clearModal()
      setLoading(false)
      setLoginType("input")
    } else {
      if (Object.keys(recoverySettings).length > 0) {
        CustomerController.fetchOne(recoverySettings.customerUid, {
          withOrders: false,
          withStreets: false,
          withMovements: false,
          withFidelities: false,
        }).then((response) => {
          if (response.status === 200) {
            if (!!response.data.contacts) {
              const firstContact = response.data.contacts[0].contact_customer.val_contact
              setUsername(firstContact)

              if (response.data.contacts[0].id_contact === 1) {
                setLoginType("phone")
              }

              if (response.data.contacts[0].id_contact === 2) {
                setLoginType("email")
              }
            }
          }
        })
      }
    }
  }, [status])

  const onChangeUsername = (event) => {
    const contact = event.target.value
    setValidUsername("")
    setCustomerName("")
    setHavePhoneLogin(false)
    setPhone("")
    setEmail("")
    setPassword("")
    setConfirmationPassword("")
    setValidations({
      username: false,
      customerName: false,
      phone: false,
      email: false,
      password: false,
    })
    setFooterButton({
      text: "Continuar",
      action: "verify",
    })

    setUsername(contact)
  }

  const normalizeLogin = () => {
    let login = username
    if (loginType === "phone") {
      login = login.replace(/[^a-z0-9]/gi, "")
    } else if (loginType === "email") {
      login = login.replace(/\s/g, "")
    }
    return login
  }

  const verifyUsername = () => {
    setLoading(true)
    let login = null
    let secondaryLogin = null
    if (loginType === "phone") {
      const formatValue = formatPhone(username, false)
      const value = username.replace(/[^a-z0-9]/gi, "")
      const additionalCaracters = !!value && !!formatValue ? value.length - formatValue.length : 0
      let maxLength = 11 + additionalCaracters
      if (!!formatValue && formatValue.length === 11) {
        maxLength = ["6", "7", "8", "9"].includes(formatValue.slice(2, 10)[0]) ? 11 + additionalCaracters : 10 + additionalCaracters
      }
      if (value.length <= maxLength) {
        let searchValue = formatValue

        let secundarySearchValue = null
        if (formatValue.length === 9) {
          searchValue = formatValue.slice(1, 9)
        } else if (formatValue.length === 10) {
          const ddd = formatValue.slice(0, 2)
          const number = formatValue.slice(2, 10)
          secundarySearchValue = ["6", "7", "8", "9"].includes(number[0]) ? ddd + "9" + number : null
        } else if (formatValue.length === 11) {
          const ddd = formatValue.slice(0, 2)
          const number = formatValue.slice(3, 11)
          secundarySearchValue = ["6", "7", "8", "9"].includes(number[0]) ? ddd + number : null
        }

        login = searchValue
        secondaryLogin = secundarySearchValue
      }
    } else if (loginType === "email") {
      login = username
    }

    // console.log({ loginType, login, username })
    if (!login) {
      onMessageDialog("warning").fire({
        title: `E-mail ou telefone em formato inválido.`,
        icon: "warning",
        timer: 4000,
        showCancelButton: false,
        showConfirmButton: false,
        reverseButtons: true,
      })
      setLoading(false)
    } else {
      UserController.verify(login, secondaryLogin)
        .then((responseVerify) => {
          console.log("🚀 ~ .then ~ responseVerify:", responseVerify)
          // console.log("responseVerify  UserController.verify", responseVerify)
          if (responseVerify.status === 200) {
            setCustomerName(responseVerify.data.nm_customer)
            setHavePhoneLogin(responseVerify.data.havePhone)
            setValidUsername(true)
            setActionUpdate(false)
            setFooterButton({
              text: "Entrar",
              action: "login",
            })
            setCustomerUid(responseVerify.data.uuid_customer)

            if (!!responseVerify.data.haveEmail) {
              setCustomerHasEmail(responseVerify.data.haveEmail)
            }

            let isLinkExpired = false

            if (Object.keys(recoverySettings).length > 0) {
              const linkDate = recoverySettings.expiresIn * 1000
              const actualDate = Date.now() * 1000

              if (linkDate < actualDate) {
                GeneralTools.swalMessage("warning").fire({
                  icon: "warning",
                  title: "Erro!",
                  text: "Parece que o link que você está tentando usar já expirou, solicite um link novo e tente novamente!",
                })

                isLinkExpired = true
              }
            }

            if (
              responseVerify.data.isPasswordExpired ||
              !responseVerify.data.havePhone ||
              (Object.keys(recoverySettings).length > 0 && !isLinkExpired)
            ) {
              setActionUpdate(true)
              setFooterButton({
                text: "Atualizar",
                action: "update",
              })
            }
          } else if (responseVerify.status === 202) {
            //Caso não localize nenhum login com o username passado, como está sendo tratado com um like, então apenas em casos que realmente não exista nenhum login parecido
            if (responseVerify.message === "Login não identificado") {
              setValidUsername(false)
              setCustomerName("")
              setHavePhoneLogin(false)
              setPassword("")
              setFooterButton({
                text: "Cadastrar",
                action: "register",
              })
            }
            //Caso ele localize mais de 1 registro na logins com o username informado, neste caso o cliente deverá informar mais dados do login.
            if (responseVerify.message === "Não foi possível identificar seu login") {
              onMessageDialog("warning").fire({
                title: `Não foi possivel identificar o login informado ou seu telefone/e-mail possui mais de um cadastro.`,
                icon: "warning",
                timer: 4000,
                showCancelButton: false,
                showConfirmButton: false,
                reverseButtons: true,
              })
            }
            //Caso cliente tente logar com um contato que tenha código diferente de 1 (celular) ou 2 (email)
            if (responseVerify.message === "Login permitido apenas por celular ou email") {
              onMessageDialog("warning").fire({
                title: `Login permitido apenas por celular ou email`,
                icon: "warning",
                timer: 4000,
                showCancelButton: false,
                showConfirmButton: false,
                reverseButtons: true,
              })
            }
          } else if (responseVerify.status === 203) {
            if (!responseVerify.data.havePhone || responseVerify.data.isPasswordExpired) {
              setCustomerName(responseVerify.data.nm_customer)
              setHavePhoneLogin(responseVerify.data.havePhone)
              setValidUsername(true)
              setActionUpdate(true)
              setCustomerUid(responseVerify.data.uuid_customer)
              setFooterButton({
                text: "Atualizar",
                action: "update",
              })
            } else {
              onSignIn(login)
            }
          }
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const clearModal = () => {
    setActionUpdate(false)
    setUsername("")
    setLoginType("input")
    setValidUsername("")
    setPhone("")
    setCustomerName("")
    setHavePhoneLogin(false)
    setEmail("")
    setPassword("")
    setFooterButton({
      text: "Continuar",
      action: "verify",
    })
  }

  const onRegister = async () => {
    if (finalizingCustomerRegisterRef.current === false) {
      const status = validateData()
      const { id_company } = company

      if (status) {
        //Continua para o cadastro
        let login = normalizeLogin(username)

        setLoading(true)
        finalizingCustomerRegisterRef.current = true
        const content = {
          login,
          customerName,
          phone,
          email,
          password: !formatBoolean(settings?.site_allow_no_pass_login) ? btoa(password) : null,
          id_company,
        }
        const secondaryLogin = phone !== "" ? formatPhone(phone, false) : email !== "" ? email : null
        UserController.verifyContacts(login, secondaryLogin)
          .then((responseVerify) => {
            if (responseVerify.status === 200) {
              CustomerController.insert(content, loginType)
                .then((responseCreate) => {
                  if (responseCreate.status === 201) {
                    onSignIn(login, !formatBoolean(settings.site_allow_no_pass_login) ? password : null, true)

                    clearModal()
                    finalizingCustomerRegisterRef.current = false
                  } else if (responseCreate.status === 401) {
                    onMessageDialog("warning")
                      .fire({
                        title: `Telefone e/ou e-mail já cadastrado! Tente realizar login através do ${loginType === "phone" ? "e-mail" : "telefone"}`,
                        icon: "warning",
                        timer: 4000,
                        showCancelButton: false,
                        showConfirmButton: false,
                        reverseButtons: true,
                      })
                      .then()
                    finalizingCustomerRegisterRef.current = false
                  } else {
                    onMessageDialog("warning")
                      .fire({
                        title: responseCreate.data.message,
                        icon: "warning",
                        timer: 4000,
                        showCancelButton: false,
                        showConfirmButton: false,
                        reverseButtons: true,
                      })
                      .then()
                    finalizingCustomerRegisterRef.current = false
                  }
                })
                .catch((error) => {
                  console.error(error)
                  finalizingCustomerRegisterRef.current = false
                })
                .finally(() => {
                  setLoadingRegister(false)
                })
            } else if (responseVerify.status === 400) {
              onMessageDialog("warning")
                .fire({
                  title: `Telefone e/ou e-mail já cadastrado! Tente realizar login através do ${loginType === "phone" ? "e-mail" : "telefone"}`,
                  icon: "warning",
                  timer: 4000,
                  showCancelButton: false,
                  showConfirmButton: false,
                  reverseButtons: true,
                })
                .then()
              finalizingCustomerRegisterRef.current = false
            } else {
              onMessageDialog("warning")
                .fire({
                  title: `Ocorreu um erro ao verificar seus contatos, por favor, tente novamente."}`,
                  icon: "warning",
                  timer: 4000,
                  showCancelButton: false,
                  showConfirmButton: false,
                  reverseButtons: true,
                })
                .then()
              finalizingCustomerRegisterRef.current = false
            }
          })
          .catch((error) => {
            console.error(error)
            finalizingCustomerRegisterRef.current = false
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }
  }

  const onUpdate = async () => {
    let validation = {
      password: false,
      passwordConfirmation: false,
    }
    let status = true

    if (!formatBoolean(settings.site_allow_no_pass_login)) {
      if (password.length === 0) {
        onMessageDialog("warning").fire({
          title: "Senha não informada!",
          icon: "warning",
          timer: 4000,
          showCancelButton: false,
          showConfirmButton: false,
          reverseButtons: true,
        })
        validation = { ...validation, password: true }
        status = false
      }

      if (password !== confirmationPassword) {
        onMessageDialog("warning").fire({
          title: "As senhas não conferem!",
          icon: "warning",
          timer: 4000,
          showCancelButton: false,
          showConfirmButton: false,
          reverseButtons: true,
        })

        validation = { ...validation, passwordConfirmation: true }
        status = false
      }
    }

    if (!havePhoneLogin) {
      const [statusPhone, validationMessage] = phoneValidation(phone)

      if (!statusPhone) {
        onMessageDialog("warning").fire({
          title: validationMessage,
          icon: "warning",
          timer: 4000,
          showCancelButton: false,
          showConfirmButton: false,
          reverseButtons: true,
        })
        validation = { ...validations, phone: true }
        status = statusPhone
      } else {
        let login = null
        let secondaryLogin = null

        const formatValue = formatPhone(phone, false)
        const value = phone.replace(/[^a-z0-9]/gi, "")
        const additionalCaracters = !!value && !!formatValue ? value.length - formatValue.length : 0
        let maxLength = 11 + additionalCaracters
        if (!!formatValue && formatValue.length === 11) {
          maxLength = ["6", "7", "8", "9"].includes(formatValue.slice(2, 10)[0]) ? 11 + additionalCaracters : 10 + additionalCaracters
        }
        if (value.length <= maxLength) {
          let searchValue = formatValue

          let secundarySearchValue = null
          if (formatValue.length === 9) {
            searchValue = formatValue.slice(1, 9)
          } else if (formatValue.length === 10) {
            const ddd = formatValue.slice(0, 2)
            const number = formatValue.slice(2, 10)
            secundarySearchValue = ["6", "7", "8", "9"].includes(number[0]) ? ddd + "9" + number : null
          } else if (formatValue.length === 11) {
            const ddd = formatValue.slice(0, 2)
            const number = formatValue.slice(3, 11)
            secundarySearchValue = ["6", "7", "8", "9"].includes(number[0]) ? ddd + number : null
          }

          login = searchValue
          secondaryLogin = secundarySearchValue
        }

        await UserController.verifyContacts(login, secondaryLogin)
          .then((responseVerify) => {
            // console.log("responseVerify verifyContacts", responseVerify)
            if (responseVerify.status === 400) {
              onMessageDialog("warning").fire({
                title: `Telefone já cadastrado! Verifique o número ou tente realizar login através do ${
                  loginType === "phone" ? "e-mail" : "telefone"
                }`,
                icon: "warning",
                timer: 4000,
                showCancelButton: false,
                showConfirmButton: false,
                reverseButtons: true,
              })
              validation = { ...validations, phone: true }
              status = false
            } else if (responseVerify.status !== 200) {
              onMessageDialog("warning").fire({
                title: `Ocorreu um erro ao verificar seus contatos, por favor, tente novamente."}`,
                icon: "warning",
                timer: 4000,
                showCancelButton: false,
                showConfirmButton: false,
                reverseButtons: true,
              })
              validation = { ...validations, phone: true }
              status = false
            }
          })
          .catch((error) => {
            console.error(error)
            validation = { ...validations, phone: true }
            status = false
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }
    if (status) {
      setValidations({
        username: false,
        customerName: false,
        phone: false,
        email: false,
        password: false,
        passwordConfirmation: false,
      })
      //Continua para o cadastro
      setLoadingRegister(true)
      const updatedPhone = !havePhoneLogin ? phone : null
      const onlyPhone = formatBoolean(settings?.site_allow_no_pass_login)

      CustomerController.updatePass(customerUid, encrypt(password, "base64"), null, updatedPhone, onlyPhone)
        .then((responseCreate) => {
          if (responseCreate.status === 201) {
            onSignIn(normalizeLogin(username), !formatBoolean(settings.site_allow_no_pass_login) ? password : null).then((response) => {
              if (response) {
                clearModal()
                actionUpdate(false)
              }
            })
            clearModal()
          } else {
            onMessageDialog("warning").fire({
              title: responseCreate.data.message,
              icon: "warning",
              timer: 4000,
              showCancelButton: false,
              showConfirmButton: false,
              reverseButtons: true,
            })
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setLoadingRegister(false)
        })
    } else {
      setValidations(validation)
    }
  }

  const validateData = () => {
    let validation = {
      username: false,
      customerName: false,
      phone: false,
      email: false,
      password: false,
      passwordConfirmation: false,
    }
    let status = true

    if (loginType === "phone") {
      const [statusPhone, validationMessage] = phoneValidation(username)

      if (!statusPhone) {
        onMessageDialog("warning").fire({
          title: validationMessage,
          icon: "warning",
          timer: 4000,
          showCancelButton: false,
          showConfirmButton: false,
          reverseButtons: true,
        })
        validation = { ...validations, username: true }
        status = statusPhone
      }
      if (email.length > 1) {
        if (email.indexOf("@") === -1 || email.indexOf(".") === -1 || email.indexOf("@") > email.lastIndexOf(".")) {
          onMessageDialog("warning").fire({
            title: "Formato do email inválido",
            icon: "warning",
            timer: 4000,
            showCancelButton: false,
            showConfirmButton: false,
            reverseButtons: true,
          })
          validation = { ...validations, email: true }
          status = false
        }
      }
    }

    if (loginType === "email") {
      if (username.indexOf("@") === -1 || username.indexOf(".") === -1 || username.indexOf("@") > username.lastIndexOf(".")) {
        onMessageDialog("warning").fire({
          title: "Formato do email inválido",
          icon: "warning",
          timer: 4000,
          showCancelButton: false,
          showConfirmButton: false,
          reverseButtons: true,
        })
        validation = { ...validations, username: true }
        status = false
      }

      const [statusPhone, validationMessage] = phoneValidation(phone)

      if (!statusPhone) {
        onMessageDialog("warning").fire({
          title: validationMessage,
          icon: "warning",
          timer: 4000,
          showCancelButton: false,
          showConfirmButton: false,
          reverseButtons: true,
        })
        validation = { ...validation, phone: true }
        status = statusPhone
      }
    }

    if (customerName.length < 3) {
      onMessageDialog("warning").fire({
        title: "Faltou informar um nome válido",
        icon: "warning",
        timer: 4000,
        showCancelButton: false,
        showConfirmButton: false,
        reverseButtons: true,
      })
      validation = { ...validation, customerName: true }
      status = false
    }

    if (!formatBoolean(settings.site_allow_no_pass_login)) {
      if (password.length === 0) {
        onMessageDialog("warning").fire({
          title: "Senha não informada!",
          icon: "warning",
          timer: 4000,
          showCancelButton: false,
          showConfirmButton: false,
          reverseButtons: true,
        })
        validation = { ...validation, password: true }
        status = false
      }

      if (password !== confirmationPassword) {
        onMessageDialog("warning").fire({
          title: "As senhas não conferem!",
          icon: "warning",
          timer: 4000,
          showCancelButton: false,
          showConfirmButton: false,
          reverseButtons: true,
        })
        validation = { ...validation, passwordConfirmation: true }
        status = false
      }
    }

    if (!status) {
      setValidations(validation)
      return false
    } else {
      setValidations({ username: false, customerName: false, phone: false, email: false, password: false })
      return status
    }
  }

  const phoneValidation = (phone) => {
    let status = true
    let validationMessage = ""
    //retira todos os caracteres menos os numeros
    phone = phone.replace(/\D/g, "")

    //Método para que o cliente não coloque números repetidos como 900000000, 99999999, etc.
    const validateEqualsNumbers = () => {
      for (let n = 0; n < 10; n++) {
        const testeValue = new Array(9).join(n)
        if (phone.indexOf(testeValue) !== -1) return true
      }
      return false
    }
    //verifica se tem a quantidade de numeros valida (8 a 11) contando com o ddd ou não
    if (phone.length !== 11) {
      status = false
      validationMessage = "Telefone com a quantidade de números inválida"
    }

    if (phone.length === 8) {
      //Telefone fixo sem DDD informado
      const equalsNumbers = validateEqualsNumbers(9)
      if (equalsNumbers) {
        status = false
        validationMessage = "Digite um número de telefone válido!"
      }
    } else if (phone.length === 9) {
      //Telefone celular sem DDD informado

      const equalsNumbers = validateEqualsNumbers(9)
      if (equalsNumbers) {
        status = false
        validationMessage = "Digite um número de telefone válido!"
      }

      //Verifica se o celular começa com 9 ou 8
      if (parseInt(phone.substring(0, 1)) !== 9 && parseInt(phone.substring(0, 1)) !== 8) {
        status = false
        validationMessage = "Telefone celular deve começar com os número 8 ou 9!"
      }
    }
    return [status, validationMessage]
  }

  const onActionFooterButton = () => {
    if (!!footerButton) {
      switch (footerButton.action) {
        case "verify": {
          verifyUsername(username)
          break
        }
        case "login": {
          onSignIn(normalizeLogin(username), password)
          break
        }
        case "register": {
          onRegister().then()
          break
        }
        case "update": {
          onUpdate().then()
          break
        }
        default: {
          break
        }
      }
    }
  }

  const onKeyDownAction = (event) => {
    if (event.key === "Enter") {
      onActionFooterButton()
    }
  }

  const handleRecoveryPassword = async () => {
    if (!!customerUid) {
      await CustomerController.recoveryPassword(customerUid)
        .then((response) => {
          if (response.status === 200) {
            GeneralTools.swalMessage("success")
              .fire({
                icon: "success",
                title: "Sucesso!",
                text: response.data.message,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  onClose()
                }
              })
          } else {
            GeneralTools.swalMessage("error")
              .fire({
                icon: "error",
                title: "Erro!",
                text: response.data.message,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  onClose()
                }
              })
          }
        })
        .catch((error) => {
          console.error("Falha ao tentar recuperar senha", error)
        })
    }
  }

  return (
    <Modal isOpen={status} className="modal-signin" toggle={handleToggleClick} backdrop={"static"} fade={false}>
      {/*<div id="id-modal-signin" onClick={handleToggleClick} className="modal-signin" autoFocus={false}>*/}
      {/*<div className="modal-signin--content" style={{ marginBottom: isAppleDevice() ? 110 : 0 }}>*/}
      {loading || loadingRegister ? <FullContentLoading /> : ""}

      <ModalHeader className="modal-signin--header">
        <img src={getUrlAWSManagerFile(company.url_image)} alt="Logo Cityfoods" className="modal-signin--content--header--img-logo" />
        {<Label>Informe seu e-email ou celular</Label>}
      </ModalHeader>

      {!formatBoolean(settings?.site_allow_no_pass_login) ? (
        <div className="modal-signin--body">
          <MaskedInput
            mask={
              loginType === "email" || loginType === "input"
                ? false
                : ["(", /[1-9]/, /\d/, ")", " ", /\d/, " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
              //
            }
            className="form-control modal-signin--phone"
            placeholder="E-mail ou Celular"
            guide={false}
            value={username}
            onKeyDown={onKeyDownAction}
            autoFocus
            onChange={onChangeUsername}
          />
          {loading ? <Spinner size="sm" /> : ""}
          {typeof validUsername === "boolean" ? (
            validUsername ? (
              <div className="modal-signin--body--exist">
                <p>
                  Seja bem vindo(a) <strong>{customerName}</strong>
                </p>

                {actionUpdate ? (
                  <>
                    <p>{`Por favor, atualize ${!havePhoneLogin ? "seu telefone e senha" : "sua senha"}`}</p>
                    {!havePhoneLogin ? (
                      <MaskedInput
                        mask={["(", /[1-9]/, /\d/, ")", " ", /\d/, " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                        className={"form-control modal-signin--body--non-exist--phone" + (validations.phone ? " invalid-register" : "")}
                        placeholder="Telefone"
                        guide={false}
                        value={phone}
                        onKeyDown={onKeyDownAction}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    ) : (
                      ""
                    )}
                    <Input
                      type="password"
                      className={"modal-signin--body--exist--password" + (validations.password ? " invalid-register" : "")}
                      placeholder="Senha"
                      value={password}
                      onKeyDown={onKeyDownAction}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                    <Input
                      type="password"
                      className={"modal-signin--body--exist--confirm-password" + (validations.passwordConfirmation ? " invalid-register" : "")}
                      placeholder="Confirme a senha"
                      value={confirmationPassword}
                      onKeyDown={onKeyDownAction}
                      onChange={(event) => setConfirmationPassword(event.target.value)}
                    />
                  </>
                ) : (
                  <>
                    <p>Por favor, informe sua senha</p>
                    <Input
                      type="password"
                      className="modal-signin--body--exist--password"
                      placeholder="Senha"
                      value={password}
                      onKeyDown={onKeyDownAction}
                      autoFocus={validUsername}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                    {customerHasEmail && (
                      <button onClick={handleRecoveryPassword} className="modal-signin--body--exist-button">
                        Esqueci minha senha
                      </button>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className="modal-signin--body--non-exist">
                <p>Não foi localizado nenhum cadastro para este {loginType === "phone" ? "telefone" : "e-mail"}</p>
                <p>Você pode se cadastrar:</p>
                <Input
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  onKeyDown={onKeyDownAction}
                  className={"modal-signin--body--non-exist--name" + (validations.customerName ? " invalid-register" : "")}
                  placeholder="Nome Completo"
                />
                {loginType === "phone" ? (
                  <Input
                    type="email"
                    className={"modal-signin--body--non-exist--email" + (validations.email ? " invalid-register" : "")}
                    placeholder="E-mail (opcional)"
                    value={email}
                    onKeyDown={onKeyDownAction}
                    onChange={(event) => setEmail(event.target.value.replace(/\s/g, ""))}
                  />
                ) : (
                  <MaskedInput
                    mask={["(", /[1-9]/, /\d/, ")", " ", /\d/, " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                    className={"form-control modal-signin--body--non-exist--phone" + (validations.phone ? " invalid-register" : "")}
                    placeholder="Telefone"
                    guide={false}
                    value={phone}
                    onKeyDown={onKeyDownAction}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                )}
                <Input
                  type="password"
                  className={"modal-signin--body--non-exist--password" + (validations.password ? " invalid-register" : "")}
                  placeholder="Senha"
                  value={password}
                  onKeyDown={onKeyDownAction}
                  onChange={(event) => setPassword(event.target.value)}
                />
                <Input
                  type="password"
                  className={"modal-signin--body--non-exist--confirm-password" + (validations.passwordConfirmation ? " invalid-register" : "")}
                  placeholder="Confirme a senha"
                  value={confirmationPassword}
                  onKeyDown={onKeyDownAction}
                  onChange={(event) => setConfirmationPassword(event.target.value)}
                />
              </div>
            )
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="modal-signin--body">
          <MaskedInput
            mask={
              loginType === "email" || loginType === "input"
                ? false
                : ["(", /[1-9]/, /\d/, ")", " ", /\d/, " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
            }
            className="form-control modal-signin--phone"
            placeholder="E-mail ou Celular"
            guide={false}
            value={username}
            onKeyDown={onKeyDownAction}
            autoFocus
            onChange={onChangeUsername}
          />

          {loading ? <Spinner size="sm" /> : ""}

          {typeof validUsername === "boolean" ? (
            validUsername ? (
              <div className="modal-signin--body--exist">
                <p>
                  Seja bem vindo(a) <strong>{customerName}</strong>
                </p>

                {actionUpdate ? (
                  <>
                    <p>{"Por favor, atualize seu telefone"}</p>
                    {!havePhoneLogin ? (
                      <MaskedInput
                        mask={["(", /[1-9]/, /\d/, ")", " ", /\d/, " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                        className={"form-control modal-signin--body--non-exist--phone" + (validations.phone ? " invalid-register" : "")}
                        placeholder="Telefone"
                        guide={false}
                        value={phone}
                        onKeyDown={onKeyDownAction}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    ) : (
                      ""
                    )}

                    {/*<Input*/}
                    {/*  type="password"*/}
                    {/*  className={"modal-signin--body--exist--password" + (validations.password ? " invalid-register" : "")}*/}
                    {/*  placeholder="Senha"*/}
                    {/*  value={password}*/}
                    {/*  onKeyDown={onKeyDownAction}*/}
                    {/*  onChange={(event) => setPassword(event.target.value)}*/}
                    {/*/>*/}
                    {/*<Input*/}
                    {/*  type="password"*/}
                    {/*  className={"modal-signin--body--exist--confirm-password" + (validations.passwordConfirmation ? " invalid-register" : "")}*/}
                    {/*  placeholder="Confirme a senha"*/}
                    {/*  value={confirmationPassword}*/}
                    {/*  onKeyDown={onKeyDownAction}*/}
                    {/*  onChange={(event) => setConfirmationPassword(event.target.value)}*/}
                    {/*/>*/}
                  </>
                ) : (
                  <>
                    <p>Por favor, informe sua senha</p>
                    <Input
                      type="password"
                      className="modal-signin--body--exist--password"
                      placeholder="Senha"
                      value={password}
                      onKeyDown={onKeyDownAction}
                      autoFocus={validUsername}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </>
                )}
              </div>
            ) : (
              <div className="modal-signin--body--non-exist">
                <p>Não foi localizado nenhum cadastro para este {loginType === "phone" ? "telefone" : "e-mail"}</p>
                <p>Você pode se cadastrar:</p>
                <Input
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  onKeyDown={onKeyDownAction}
                  className={"modal-signin--body--non-exist--name" + (validations.customerName ? " invalid-register" : "")}
                  placeholder="Nome Completo"
                />
                {loginType === "phone" ? (
                  <Input
                    type="email"
                    className={"modal-signin--body--non-exist--email" + (validations.email ? " invalid-register" : "")}
                    placeholder="E-mail (opcional)"
                    value={email}
                    onKeyDown={onKeyDownAction}
                    onChange={(event) => setEmail(event.target.value.replace(/\s/g, ""))}
                  />
                ) : (
                  <MaskedInput
                    mask={["(", /[1-9]/, /\d/, ")", " ", /\d/, " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                    className={"form-control modal-signin--body--non-exist--phone" + (validations.phone ? " invalid-register" : "")}
                    placeholder="Telefone"
                    guide={false}
                    value={phone}
                    onKeyDown={onKeyDownAction}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                )}
              </div>
            )
          ) : (
            ""
          )}
        </div>
      )}

      <ModalFooter className="modal-signin--content--footer">
        {!!footerButton ? (
          <div className="modal-signin--content--footer--button">
            <CFButton title="Fechar" onClick={onClose} outline={true} />
            <CFButton onClick={() => onActionFooterButton()} title={footerButton.text} />
          </div>
        ) : (
          // <Button color="danger" onClick={}>
          //   {}
          // </Button>
          ""
        )}
      </ModalFooter>
      {/*<div className="modal-signin--content--close-button">*/}
      {/*  <FontAwesomeIcon icon="times" onClick={closeModal} />*/}
      {/*</div>*/}
    </Modal>
  )
}

export default ModalSignIn

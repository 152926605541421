// Resources
import API from "../helpers/API"
import GeneralTools from "../helpers/GeneralTools"

import {
  getParamsToCreateOrder,
} from "../helpers/OrderTools"

/**
 * Componente funcional para manipulacão dos dados de pedidos
 */
const OrderController = {
  insert(order, customer, orderType, scheduleOrder) {
    // Definindo os parametros
    let params = getParamsToCreateOrder(order, customer)
    // console.log("params", params)
    if (orderType === "schedule") {
      params = {
        ...params,
        id_neighbourhood: customer.id_neighbourhood,
        st_approved: true,
      }

      return API.request("/v1/schedule/order", { ...params, scheduleOrder }, "POST")
    }


    // return { status: 500, data: "teste" }
    return API.request("/v1/order", params, "POST", {}, 60000)
  },

  fetchOne(orderUid) {
    return API.request("/v1/order/one/" + orderUid, [], "GET")
  },

  fetchProgress(uuid_order) {
    return API.request("/v1/order/progress/" + uuid_order, [], "GET")
  },

  fetchAllByCustomer(uuid_customer) {
    return API.request("/v1/order/customer/" + uuid_customer, [], "GET")
  },

  countOrdersByCustomer(uuid_customer, endDate = null, startDate = null) {
    const params = { endDate, startDate }
    return API.request("/v1/order/customer/" + uuid_customer + "/count", params, "GET")
  },
}

export default OrderController

import React from "react"
//External libs
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//Controllers
import BenefitController from "../../controllers/BenefitController"
import FlavourController from "../../controllers/FlavourController"
import ProductController from "../../controllers/ProductController"
//Contexts
import MenuContext from "../../contexts/MenuContext"
import OrderContext from "../../contexts/OrderContext"
import SettingsContext from "../../contexts/SettingsContext"
import UserContext from "../../contexts/UserContext"
import LoadingContext from "../../contexts/LoadingContext"
//Helpers
import { getFidelityProgress, getLocalUser } from "../../helpers/UserTools"
import { formatMoney, getJsonParsed, onMessageDialog } from "../../helpers/GeneralTools"
import { getBenefitValue, getTotalOrder } from "../../helpers/OrderTools"
import { getLocalItem, getSessionItem, setLocalItem, setSessionItem } from "../../helpers/StorageTools"
//components
import BenefitFinder from "./BenefitFinder"

//Styles
import "./BenefitItem.scss"

const BenefitItem = () => {
  const { setModalSignIn } = React.useContext(MenuContext)

  const { fidelities, benefits, beverages, modality, onChangeUserSelectedAddress } = React.useContext(SettingsContext)
  const { user, userSelectedAddress } = React.useContext(UserContext)
  const { order, setOrder } = React.useContext(OrderContext)
  const { setLoading } = React.useContext(LoadingContext)

  const [loadingApply, setLoadingApply] = React.useState(false)
  const [modalBenefitFinder, setModalBenefitFinder] = React.useState(false)
  const selectedPromotionRef = React.useRef(null)
  const currentOrderRef = React.useRef(null)
  const stopRef = React.useRef(0)
  const dontRefreshRef = React.useRef(false)

  // const [showBenefitFinder, setShowBenefitFinder] = React.useState(false)
  const [selectedPromotion, setSelectedPromotion] = React.useState(null)
  const [benefitCode, setBenefitCode] = React.useState("")
  const [promotions, setPromotions] = React.useState([])
  // console.log(" ")
  // console.log(" ")
  // console.log(" ")
  // console.log("user", user)
  // console.log("fidelities", fidelities)
  // console.log("promotions", promotions)

  React.useEffect(() => {
    // console.log("useEffect", JSON.stringify(order))
    if (!dontRefreshRef.current) {
      currentOrderRef.current = order
      let availablePromotions = [
        // Quando for um cupom pro cliente usar o icon deve ser o "ticket"
        // {
        //   icon: "ticket",
        //   nm_benefit: "Cupom de brinde",
        //   uuid_benefit: "b22ffffb-c260-4a64-9174-ccef93b18a84",
        // },
      ]
      let fidelityUsed = false
      //Validando os programas de fidelidade
      if (!!fidelities && !!user?.fidelities) {
        let localFidelitiesUsed = getSessionItem("fidelitiesUsed", true)
        for (const fidelity of fidelities) {
          if (!!localFidelitiesUsed) {
            fidelityUsed = localFidelitiesUsed.find((fidelityId) => fidelityId === fidelity.id_fidelity)
          }
          if (!fidelityUsed) {
            const { actual, total, progress, readyToFinish } = getFidelityProgress(fidelity, user)
            if (readyToFinish && !fidelityUsed) {
              availablePromotions.push({ ...fidelity, actual, total, progress, icon: "award" })
            }
          }
        }
      }
      const sessionPromotions = getSessionItem("promotions", true)
      if (!!sessionPromotions) {
        const allPromotions = availablePromotions.concat(sessionPromotions)
        availablePromotions = Array.from(new Set(allPromotions.map(JSON.stringify))).map(JSON.parse)
      }
      const benefitsUsed = getSessionItem("benefitsUsed", true)
      if (benefitsUsed?.length > 0) {
        // const availablePromotions
        availablePromotions = availablePromotions.filter(({ id_benefit }) => {
          return !benefitsUsed.find((item) => item === id_benefit)
        })
      }
      //Verificando se já existe um cupom vinculado ao pedido
      //Verificando se já existe uma fidelidade vinculada ao pedido
      if (!!currentOrderRef.current.id_benefit && currentOrderRef.current.benefits.length > 0) {
        applyBenefitPromotion(currentOrderRef.current.benefits[0]).then()
        selectedPromotionRef.current = currentOrderRef.current.benefits[0].uuid_benefit
        setSelectedPromotion(selectedPromotionRef.current)
      } else if (!!currentOrderRef.current.uuid_fidelity) {
        selectedPromotionRef.current = currentOrderRef.current.uuid_fidelity
        setSelectedPromotion(selectedPromotionRef.current)
      } else {
        removeFidelityFromOrder("all").then()
        removeBenefitFromOrder("all").then()
      }
      setSessionItem("promotions", availablePromotions, true)
      setPromotions(availablePromotions)
    }
    dontRefreshRef.current = false
  }, [user, order])

  const onApplyPromotion = async (promotion) => {
    if (promotion === "clear") {
      await removeBenefitFromOrder("all")
      await removeFidelityFromOrder("all")

      dontRefreshRef.current = true
      currentOrderRef.current.fidelity = false
      selectedPromotionRef.current = null
      setOrder(currentOrderRef.current)
      setSelectedPromotion(null)
      setModalBenefitFinder(false)

      onMessageDialog("warning")
        .fire({
          title: "Cupom removido do carrinho",
          icon: "warning",
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        })
        .then()
    } else if (!!promotion.uuid_fidelity) {
      //Aplicação de promoções do tipo fidelidade
      applyFidelityPromotion(promotion).then()
    } else if (!!promotion.uuid_benefit) {
      //Aplicação de promoções do tipo cupom
      applyBenefitPromotion(promotion).then()
    }
  }

  //Adiciona o cupom no order
  const applyBenefitPromotion = async (benefit) => {
    if (currentOrderRef.current.benefits.length > 0 || !!currentOrderRef.current.id_fidelity) {
      const benefitRemoved = await removeBenefitFromOrder(benefit.uuid_benefit)
      const fidelityRemoved = await removeFidelityFromOrder("all")
      if (benefitRemoved && fidelityRemoved && stopRef.current < 10) {
        stopRef.current++
        applyBenefitPromotion(benefit).then()
      } else {
        setLoadingApply(false)
      }
    } else {
      try {
        setLoadingApply(true)
        stopRef.current = 0

        if (benefit.tp_target === "DELIVERY-FEE" && ![3, 2].includes(modality)) {
          onMessageDialog("info")
            .fire({
              title: "Este cupom é válido apenas para pedidos de entrega!",
              icon: "info",
              timer: 4000,
              showCancelButton: false,
              showConfirmButton: false,
            })
            .then()
          return
        }
        if (order.total < benefit.minimum_value) {
          onMessageDialog("info")
            .fire({
              title: `Para este cupom, o pedido mínimo é de ${formatMoney(benefit.minimum_value)}`,
              icon: "info",
              timer: 4000,
              showCancelButton: false,
              showConfirmButton: false,
            })
            .then()
          return
        }

        switch (benefit.tp_benefit) {
          //Cupom de desconto
          case 0: {
            //Desconto no carrinho
            if (benefit.tp_target === "CART") {
              const benefitIndex = promotions.findIndex(({ uuid_benefit }) => uuid_benefit === benefit.uuid_benefit)
              if (benefitIndex === -1) {
                const sessionPromotions = getSessionItem("promotions", true)
                if (!!sessionPromotions) {
                  setSessionItem("promotions", [...new Set([...sessionPromotions, { ...benefit, icon: "ticket" }])], true)
                }
                setPromotions([...promotions, { ...benefit, icon: "ticket" }])
              }
              currentOrderRef.current = {
                ...currentOrderRef.current,
                benefits: [benefit],
                id_benefit: benefit.id_benefit,
                ds_discount: (currentOrderRef.current?.ds_discount || "") + ` | Cupom de desconto: ${benefit.nm_benefit}`,
              }
            }
            //Desconto no carrinho + taxa de entrega
            if (benefit.tp_target === "SUBTOTAL") {
              const benefitIndex = promotions.findIndex(({ uuid_benefit }) => uuid_benefit === benefit.uuid_benefit)
              if (benefitIndex === -1) {
                const sessionPromotions = getSessionItem("promotions", true)
                if (!!sessionPromotions) {
                  setSessionItem("promotions", [...new Set([...sessionPromotions, { ...benefit, icon: "ticket" }])], true)
                }
                setPromotions([...promotions, { ...benefit, icon: "ticket" }])
              }
              currentOrderRef.current = {
                ...currentOrderRef.current,
                benefits: [benefit],
                id_benefit: benefit.id_benefit,
                ds_discount: (currentOrderRef.current?.ds_discount || "") + ` | Cupom de desconto: ${benefit.nm_benefit}`,
              }
            }
            //Desconto em item especifico
            if (benefit.tp_target === "ITEM") {
              const benefitIndex = promotions.findIndex(({ uuid_benefit }) => uuid_benefit === benefit.uuid_benefit)
              if (benefitIndex === -1) {
                const sessionPromotions = getSessionItem("promotions", true)
                if (!!sessionPromotions) {
                  setSessionItem("promotions", [...new Set([...sessionPromotions, { ...benefit, icon: "ticket" }])], true)
                }
                setPromotions([...promotions, { ...benefit, icon: "ticket" }])
              }
              const productData = getJsonParsed(benefit.json_gift_product, {})
              if (!!productData) {
                const hasItemOnOrder = verifyOrderProducts(productData, currentOrderRef.current)
                if (hasItemOnOrder.length > 0) {
                  for (const index of hasItemOnOrder) {
                    currentOrderRef.current.products[index].ds_obs = `Cupom de desconto: ${benefit.nm_benefit}`
                    currentOrderRef.current.products[index].id_benefit = benefit.id_benefit
                  }
                  currentOrderRef.current = {
                    ...currentOrderRef.current,
                    benefits: [benefit],
                    id_benefit: benefit.id_benefit,
                    id_benefit_product_index: hasItemOnOrder,
                  }
                } else {
                  onMessageDialog("warning").fire({
                    title: "Não foi localizado o item para desconto no pedido.",
                    icon: "info",
                    timer: 4000,
                    showCancelButton: false,
                    showConfirmButton: false,
                  })
                }
              }
            }
            //Desconto na taxa de entrega
            if (benefit.tp_target === "DELIVERY-FEE") {
              // let newDeliveryFee = formatFloat(userSelectedAddress.deliveryFee)
              // //tp_discount_type
              // if (benefit.tp_discount_type === 0) {
              //   // newDeliveryFee -= (newDeliveryFee * formatFloat(benefit.val_benefit)) / 100
              // }
              // // 1 valor fixo
              // if (benefit.tp_discount_type === 1) {
              //   // newDeliveryFee -= formatFloat(benefit.val_benefit)
              // }
              const benefitIndex = promotions.findIndex(({ uuid_benefit }) => uuid_benefit === benefit.uuid_benefit)
              if (benefitIndex === -1) {
                const sessionPromotions = getSessionItem("promotions", true)
                if (!!sessionPromotions) {
                  setSessionItem("promotions", [...new Set([...sessionPromotions, { ...benefit, icon: "ticket" }])], true)
                }
                setPromotions([...promotions, { ...benefit, icon: "ticket" }])
              }
              // setUserSelectedAddress({ ...userSelectedAddress, deliveryFee: newDeliveryFee > 0 ? newDeliveryFee : 0 })
              currentOrderRef.current = {
                ...currentOrderRef.current,
                benefits: [benefit],
                id_benefit: benefit.id_benefit,
                ds_discount: (currentOrderRef.current?.ds_discount || "") + ` | Cupom de desconto: ${benefit.nm_benefit}`,
              }
            }

            break
          }
          //Cupom de brinde
          case 1: {
            if (benefit.tp_gift_type === 0) {
              let newBeverageData = []
              let newProductData = null
              const productData = getJsonParsed(benefit.json_gift_product, {})

              //Tratando os produtos da fidelidade
              if (!!productData) {
                const { id_product = null, id_size = null, flavours = [], complements = [], beverages: productDataBeverages = [] } = productData

                if (!!id_product) {
                  newProductData = {
                    size: {},
                    amount: 1,
                    flavours: [],
                    additionals: [],
                    uuid_benefit: benefit.uuid_benefit,
                    id_benefit: benefit.id_benefit,
                    ds_obs: `Cupom de brinde: ${benefit.nm_benefit}`,
                  }
                  const products = getLocalItem("products", true)

                  const promotionalProduct = products.find((product) => product.id_product === id_product && product.st_website)
                  if (!!promotionalProduct) {
                    newProductData = {
                      ...newProductData,
                      id_product: promotionalProduct.id_product,
                      uuid_product: promotionalProduct.uuid_product,
                      nm_product: promotionalProduct.nm_product,
                      flavours: [],
                      complements: [],
                    }

                    const promotionalSize = promotionalProduct.sizes.find((size) => size.id_size === id_size && size.st_website)
                    if (!!promotionalSize && !!newProductData) {
                      newProductData = {
                        ...newProductData,
                        size: { ...promotionalSize, show: true, val_price: 0 },
                      }

                      for (const flavour of flavours) {
                        const response = await FlavourController.fetchOne(flavour.uuid_flavour, promotionalProduct.uuid_product, false, false)
                        if (response.status === 200) {
                          const { id_flavour, uuid_flavour, nm_flavour, product_association, sizes } = response.data
                          const flavourEnabled = product_association.st_website
                          const selectedFlavourSizeEnabled = sizes.find(
                            ({ id_size, flavour_size: { st_website } }) => st_website && id_size === promotionalSize.id_size
                          )
                          if (flavourEnabled && !!selectedFlavourSizeEnabled) {
                            const { flavour_size, id_size, nm_size, uuid_size } = selectedFlavourSizeEnabled
                            newProductData.flavours.push({
                              id_flavour,
                              uuid_flavour,
                              nm_flavour,
                              amount: 1,
                              additions: [],
                              restrictions: [],
                              ingredients: [],
                              optionals: [],
                              ...product_association,
                              size: { ...flavour_size, id_size, nm_size, uuid_size, val_price: 0 },
                            })
                          }
                        } else {
                          onMessageDialog("warning")
                            .fire({
                              title: "Produto indisponível",
                              icon: "warning",
                              timer: 4000,
                              showCancelButton: false,
                              showConfirmButton: false,
                              reverseButtons: true,
                            })
                            .then()
                          newProductData = null
                        }
                      }

                      if (complements.length > 0 && !!newProductData) {
                        let productComplements = []
                        const localComplements = getLocalItem(`${promotionalProduct.uuid_product}-complements`, true)

                        if (!!localComplements) {
                          productComplements = localComplements
                        } else {
                          const response = await ProductController.fetchComplements(promotionalProduct.uuid_product)
                          if (response.status === 200) {
                            // verifica se o complemento está habilitado para o site no escopo geral e ordenar pela prioridade dele.
                            productComplements = response.data.complements
                              .filter((complement) => complement.product_complement.st_website)
                              .map((complement) => {
                                const type = promotionalProduct.st_display_mode

                                let tempComplement = {
                                  ...complement,
                                  ...complement.product_complement,
                                }
                                delete tempComplement.product_complement

                                if (type === "size") {
                                  tempComplement.size = tempComplement.sizes.find(
                                    (size) => size.id_size === promotionalSize.id_size && size.complement_size.st_website === true
                                  )
                                  if (tempComplement.size === undefined) {
                                    tempComplement.size = { additional_size: { val_price: 0 } }
                                  }
                                  delete tempComplement.sizes
                                }

                                tempComplement.additionals = tempComplement.additionals.map((eachAdditional) => {
                                  let tempAdditional = {
                                    ...eachAdditional,
                                    ...eachAdditional.complement_additional,
                                  }
                                  delete tempAdditional.complement_additional
                                  if (type === "size") {
                                    tempAdditional.size = eachAdditional.sizes.find((size) => {
                                      return size.id_size === promotionalSize.id_size && size.additional_size.st_website === true
                                    }) || { additional_size: { val_price: 0 } }
                                    delete tempAdditional.sizes
                                  }
                                  return tempAdditional
                                })
                                return tempComplement
                              })
                          }

                          setLocalItem(`${promotionalProduct.uuid_product}-complements`, productComplements, true)
                        }

                        if (!!productComplements) {
                          for (const complement of complements) {
                            let promotionalComplement = productComplements.find(
                              ({ fk_id_complement }) => fk_id_complement === complement.id_complement
                            )

                            if (!!promotionalComplement) {
                              let promotionalAdditional = promotionalComplement.additionals.find(
                                ({ id_additional }) => id_additional === complement.id_additional
                              )
                              if (!!promotionalAdditional) {
                                //Zerando todos os valores de complementos e adicionais
                                promotionalComplement = {
                                  ...promotionalComplement,
                                  size: {
                                    ...promotionalComplement.size,
                                    complement_size: { ...promotionalComplement.size.complement_size, val_price: 0 },
                                  },
                                }
                                promotionalAdditional = {
                                  ...promotionalAdditional,
                                  size: {
                                    ...promotionalAdditional.size,
                                    additional_size: { ...promotionalAdditional.size.additional_size, val_price: 0 },
                                  },
                                }
                                const selectedAdditionals = [
                                  ...newProductData.complements,
                                  {
                                    ...promotionalComplement,
                                    additionals: [{ ...promotionalAdditional, val_price: 0 }],
                                    amount: 1,
                                    val_price: 0,
                                  },
                                ]

                                newProductData = {
                                  ...newProductData,
                                  complements: selectedAdditionals,
                                }
                              }
                            } else {
                              onMessageDialog("warning")
                                .fire({
                                  title: "Produto indisponível",
                                  icon: "warning",
                                  timer: 4000,
                                  showCancelButton: false,
                                  showConfirmButton: false,
                                  reverseButtons: true,
                                })
                                .then()
                              newProductData = null
                            }
                          }
                        }
                      }
                    } else {
                      onMessageDialog("warning")
                        .fire({
                          title: "Produto indisponível",
                          icon: "warning",
                          timer: 4000,
                          showCancelButton: false,
                          showConfirmButton: false,
                          reverseButtons: true,
                        })
                        .then()
                      newProductData = null
                    }
                  } else {
                    onMessageDialog("warning")
                      .fire({
                        title: "Produto indisponível",
                        icon: "warning",
                        timer: 4000,
                        showCancelButton: false,
                        showConfirmButton: false,
                        reverseButtons: true,
                      })
                      .then()
                    newProductData = null
                  }
                }

                if (!!productDataBeverages && productDataBeverages.length > 0) {
                  for (const beverage of productDataBeverages) {
                    const promotionalBeverage = beverages.find(({ id_beverage }) => beverage.id_beverage === id_beverage)
                    if (!!promotionalBeverage && !!newBeverageData) {
                      newBeverageData.push({
                        ...promotionalBeverage,
                        amount: 1,
                        val_price: 0,
                        id_benefit: benefit.id_benefit,
                        uuid_benefit: benefit.uuid_benefit,
                      })
                    } else {
                      onMessageDialog("warning")
                        .fire({
                          title: "Produto indisponível",
                          icon: "warning",
                          timer: 4000,
                          showCancelButton: false,
                          showConfirmButton: false,
                          reverseButtons: true,
                        })
                        .then()
                      newBeverageData = null
                    }
                  }
                }
              }

              //Tratando as bebidas da fidelidade
              if (!!newProductData) {
                currentOrderRef.current.products.push(newProductData)
              }
              if (!!newBeverageData) {
                for (const beverage of newBeverageData) {
                  currentOrderRef.current.beverages.push({ ...beverage })
                }
              }
              if (!!newProductData || !!newBeverageData) {
                const benefitIndex = promotions.findIndex(({ uuid_benefit }) => uuid_benefit === benefit.uuid_benefit)
                if (benefitIndex === -1) {
                  const sessionPromotions = getSessionItem("promotions", true)
                  if (!!sessionPromotions) {
                    setSessionItem("promotions", [...new Set([...sessionPromotions, { ...benefit, icon: "ticket" }])], true)
                  }
                  setPromotions([...promotions, { ...benefit, icon: "ticket" }])
                }
                currentOrderRef.current = {
                  ...currentOrderRef.current,
                  benefits: [benefit],
                  id_benefit: benefit.id_benefit,
                  ds_discount: (currentOrderRef.current?.ds_discount || "") + ` | Cupom de brinde: ${benefit.nm_benefit}`,
                }
              }
            }

            if (benefit.tp_gift_type === 1) {
              const benefitIndex = promotions.findIndex(({ uuid_benefit }) => uuid_benefit === benefit.uuid_benefit)
              if (benefitIndex === -1) {
                const sessionPromotions = getSessionItem("promotions", true)
                if (!!sessionPromotions) {
                  setSessionItem("promotions", [...new Set([...sessionPromotions, { ...benefit, icon: "ticket" }])], true)
                }
                setPromotions([...promotions, { ...benefit, icon: "ticket" }])
              }
              // setUserSelectedAddress({ ...userSelectedAddress, deliveryFee: 0 })
              currentOrderRef.current = {
                ...currentOrderRef.current,
                benefits: [benefit],
                id_benefit: benefit.id_benefit,
                ds_discount: (currentOrderRef.current?.ds_discount || "") + ` | Cupom de brinde: ${benefit.nm_benefit}`,
              }
            }
            break
          }
          default: {
            break
          }
        }

        const [total, subTotal] = getTotalOrder(currentOrderRef.current, userSelectedAddress?.deliveryFee)
        currentOrderRef.current = { ...currentOrderRef.current, total, subTotal }
        if (currentOrderRef.current.benefits.length > 0) {
          selectedPromotionRef.current = benefit.uuid_benefit
          setSelectedPromotion(benefit.uuid_benefit)
        }
        const newOrder = { ...currentOrderRef.current }
        setOrder(newOrder)
        setLoading(false)

        if (!!selectedPromotionRef.current) {
          onMessageDialog("success")
            .fire({
              title: "Cupom aplicado com sucesso!",
              icon: "success",
              timer: 2000,
              showCancelButton: false,
              showConfirmButton: false,
            })
            .then()
          setModalBenefitFinder(false)
        }
      } catch (error) {
        console.error("Erro ao apicar cupom de beneficio: ", error)
      } finally {
        setLoadingApply(false)
      }
    }
  }

  //Método para verificar se existe no pedido algum protudo para aplicação de desconto
  const verifyOrderProducts = (productData, order) => {
    if (!!productData) {
      let hasProduct = !!productData.id_product ? productData.id_product : null
      let hasSize = !!productData.id_size ? productData.id_size : null
      let hasFlavours = !!productData.flavours ? productData.flavours : []
      let hasComplements = !!productData.complements ? productData.complements : []
      let hasBeverages = !!productData.beverages ? productData.beverages : []
      let orderProduct = order.products
      let indexProduct = []
      for (let index = 0; index < orderProduct.length; index++) {
        let product = {
          ...orderProduct[index],
          beverages: order.beverages.filter(({ nro_product }) => nro_product === orderProduct[index].nro_product),
        }

        if (!!product.id_combo) {
          break
        }

        let validations = []

        if (!!hasProduct) {
          if (product.id_product === hasProduct) {
            validations.push(true)
          } else {
            validations.push(false)
            break
          }
        }

        if (!!hasSize) {
          if (product.size.id_size === hasSize) {
            validations.push(true)
          } else {
            validations.push(false)
          }
        }

        //Verifica se a quantidade de sabores e os sabores em si são iguais
        if (hasFlavours.length > 0 && product.flavours.length > 0 && hasFlavours.length === product.flavours.length) {
          for (let i = 0; i < hasFlavours.length; i++) {
            for (let j = 0; j < product.flavours.length; j++) {
              if (product.flavours[j].id_flavour === hasFlavours[i].id_flavour) {
                hasFlavours[i] = true
                break
              }
            }
          }
          if (hasFlavours.every((item) => item === true)) {
            validations.push(true)
          } else {
            validations.push(false)
          }
        } else {
          validations.push(false)
        }

        //Verifica se a quantidade de complementos e os complementos em si são iguais
        if (hasComplements.length > 0 || product.complements.length > 0) {
          if (product.complements.length === hasComplements.length) {
            for (let i = 0; i < hasComplements.length; i++) {
              for (let j = 0; j < product.complements.length; j++) {
                if (product.complements[j].id_additional === hasComplements[i].id_additional) {
                  hasComplements[i] = true
                  break
                }
              }
            }
            if (hasComplements.length > 0 && hasComplements.every((item) => item === true)) {
              validations.push(true)
            } else {
              validations.push(false)
            }
          } else {
            validations.push(false)
          }
        }

        //Verifica se a quantidade de bebidas e as bebidas em si são iguais
        if (hasBeverages.length > 0 || product.beverages.length > 0) {
          if (product.beverages.length === hasBeverages.length) {
            for (let i = 0; i < hasBeverages.length; i++) {
              for (let j = 0; j < product.beverages.length; j++) {
                if (product.beverages[j].id_beverage === hasBeverages[i].id_beverage) {
                  hasBeverages[i] = true
                  break
                }
              }
            }

            if (hasBeverages.length > 0 && hasBeverages.every((item) => item === true)) {
              validations.push(true)
            } else {
              validations.push(false)
            }
          } else {
            validations.push(false)
          }
        }

        //Se todas as validações desse produto ocorreram com sucesso, é necessário calcular o valor
        // do cashback em cima do produto em si
        if (validations.length > 0 && validations.every((validation) => validation)) {
          indexProduct.push(index)
        }
      }

      return indexProduct
    } else {
      return -1
    }
  }

  //Adiciona a campanha de fidelidade no order
  const applyFidelityPromotion = async (fidelity) => {
    // console.log("fidelity", fidelity)
    let selectedFidelity = null
    setLoading(true)
    // console.log("currentOrderRef.current", 1, currentOrderRef.current)
    //Verificar se essa fidelidade já está aplicada
    if (!!selectedPromotionRef.current) {
      const selected = selectedPromotionRef.current
      //Revemo todos os beneficios e promoções que tiverem no order
      const removedFidelity = await removeFidelityFromOrder(selected)
      // console.log("removedFidelity", removedFidelity)
      const removedBenefit = await removeBenefitFromOrder("all")
      // console.log("removedBenefit", removedBenefit)
      // Caso não haja mais benefícios e promoções no pedido
      // Verifica se o que foi selecionado antes é igual ao anterior
      // se for igual ao anterior, apenas zera o benefício selecionado
      // se não adiciona o novo no pedido
      if (removedFidelity && removedBenefit && selected !== fidelity.uuid_fidelity) {
        applyFidelityPromotion(fidelity).then()
      } else {
        setSelectedPromotion(null)
        selectedPromotionRef.current = null
        setOrder(currentOrderRef.current)
        setLoading(false)
      }
    } else {
      switch (fidelity.prize_fidelity) {
        case "product": {
          let newBeverageData = []
          let newProductData = null
          const productData = JSON.parse(fidelity.prize_product)
          // console.log("productData", productData)
          //Tratando os produtos da fidelidade
          if (!!productData) {
            const { id_product = null, id_size = null, flavours = [], complements = [], beverages: productDataBeverages = [] } = productData

            if (!!id_product) {
              newProductData = {
                size: {},
                amount: 1,
                flavours: [],
                additionals: [],
                uuid_fidelity: fidelity.uuid_fidelity,
                id_fidelity: fidelity.id_fidelity,
                ds_obs: `Programa de fidelidade: ${fidelity.nm_fidelity}`,
              }
              // console.log("newProductData", 1, newProductData)
              const products = getLocalItem("products", true)
              // console.log("products", products)

              //Verificando se o produto da promoção está na lista de produtos habilitados do site.
              const promotionalProduct = products.find((product) => product.id_product === id_product && product.st_website)
              // console.log("promotionalProduct", promotionalProduct)

              if (!!promotionalProduct) {
                newProductData = {
                  ...newProductData,
                  id_product: promotionalProduct.id_product,
                  uuid_product: promotionalProduct.uuid_product,
                  nm_product: promotionalProduct.nm_product,
                  flavours: [],
                  complements: [],
                }
                // console.log("newProductData", 2, newProductData)

                //Verificando se o tamanho da promoção está na lista de tamanhos habilitados do site.
                const promotionalSize = promotionalProduct.sizes.find((size) => size.id_size === id_size && size.st_website)
                // console.log("promotionalSize", promotionalSize)

                if (!!promotionalSize && !!newProductData) {
                  newProductData = {
                    ...newProductData,
                    size: { ...promotionalSize, val_price: 0 },
                  }
                  // console.log("newProductData", 3, newProductData)
                  for (const flavour of flavours) {
                    //Verificando se o sabor da promoção está na lista de sabores habilitados do site.
                    const response = await FlavourController.fetchOne(flavour.uuid_flavour, promotionalProduct.uuid_product, false, false)
                    if (response.status === 200) {
                      const { id_flavour, uuid_flavour, nm_flavour, product_association, sizes } = response.data
                      const flavourEnabled = product_association.st_website
                      const selectedFlavourSizeEnabled = sizes.find(
                        ({ id_size, flavour_size: { st_website } }) => st_website && id_size === promotionalSize.id_size
                      )
                      if (flavourEnabled && !!selectedFlavourSizeEnabled) {
                        const { flavour_size, id_size, nm_size, uuid_size } = selectedFlavourSizeEnabled
                        newProductData.flavours.push({
                          id_flavour,
                          uuid_flavour,
                          nm_flavour,
                          amount: 1,
                          additions: [],
                          restrictions: [],
                          ingredients: [],
                          optionals: [],
                          ...product_association,
                          size: { ...flavour_size, id_size, nm_size, uuid_size, val_price: 0 },
                        })
                      }
                    } else {
                      onMessageDialog("warning").fire({
                        title: "Não foi possível aplicar esta promoção",
                        icon: "warning",
                        timer: 4000,
                        showCancelButton: false,
                        showConfirmButton: false,
                        reverseButtons: true,
                      })
                      newProductData = null
                      selectedFidelity = null
                    }
                  }
                  // console.log("newProductData", 4, newProductData)
                  if (complements.length > 0 && !!newProductData) {
                    let productComplements = []
                    const localComplements = getLocalItem(`${promotionalProduct.uuid_product}-complements`, true)

                    if (!!localComplements) {
                      productComplements = localComplements
                    } else {
                      const response = await ProductController.fetchComplements(promotionalProduct.uuid_product)
                      if (response.status === 200) {
                        // verifica se o complemento está habilitado para o site no escopo geral e ordenar pela prioridade dele.
                        productComplements = response.data.complements
                          .filter((complement) => complement.product_complement.st_website)
                          .map((complement) => {
                            const type = promotionalProduct.st_display_mode

                            let tempComplement = {
                              ...complement,
                              ...complement.product_complement,
                            }
                            delete tempComplement.product_complement

                            if (type === "size") {
                              tempComplement.size = tempComplement.sizes.find(
                                (size) => size.id_size === promotionalSize.id_size && size.complement_size.st_website === true
                              )
                              if (tempComplement.size === undefined) {
                                tempComplement.size = { additional_size: { val_price: 0 } }
                              }
                              delete tempComplement.sizes
                            }

                            tempComplement.additionals = tempComplement.additionals.map((eachAdditional) => {
                              let tempAdditional = {
                                ...eachAdditional,
                                ...eachAdditional.complement_additional,
                              }
                              delete tempAdditional.complement_additional
                              if (type === "size") {
                                tempAdditional.size = eachAdditional.sizes.find((size) => {
                                  return size.id_size === promotionalSize.id_size && size.additional_size.st_website === true
                                }) || { additional_size: { val_price: 0 } }
                                delete tempAdditional.sizes
                              }
                              return tempAdditional
                            })
                            return tempComplement
                          })
                      }

                      setLocalItem(`${promotionalProduct.uuid_product}-complements`, productComplements, true)
                    }

                    if (!!productComplements) {
                      for (const complement of complements) {
                        let promotionalComplement = productComplements.find(({ fk_id_complement }) => fk_id_complement === complement.id_complement)

                        if (!!promotionalComplement) {
                          let promotionalAdditional = promotionalComplement.additionals.find(
                            ({ id_additional }) => id_additional === complement.id_additional
                          )
                          if (!!promotionalAdditional) {
                            //Zerando todos os valores de complementos e adicionais
                            promotionalComplement = {
                              ...promotionalComplement,
                              size: {
                                ...promotionalComplement.size,
                                complement_size: { ...promotionalComplement.size.complement_size, val_price: 0 },
                              },
                            }
                            promotionalAdditional = {
                              ...promotionalAdditional,
                              size: {
                                ...promotionalAdditional.size,
                                additional_size: { ...promotionalAdditional.size.additional_size, val_price: 0 },
                              },
                            }
                            const selectedAdditionals = [
                              ...newProductData.complements,
                              {
                                ...promotionalComplement,
                                additionals: [{ ...promotionalAdditional, val_price: 0 }],
                                amount: 1,
                                val_price: 0,
                              },
                            ]

                            newProductData = {
                              ...newProductData,
                              complements: selectedAdditionals,
                            }
                          }
                        } else {
                          onMessageDialog("warning")
                            .fire({
                              title: "Não foi possível aplicar esta promoção",
                              icon: "warning",
                              timer: 4000,
                              showCancelButton: false,
                              showConfirmButton: false,
                              reverseButtons: true,
                            })
                            .then()
                          newProductData = null
                          selectedFidelity = null
                        }
                      }
                    }
                  }
                } else {
                  onMessageDialog("warning")
                    .fire({
                      title: "Não foi possível aplicar esta promoção",
                      icon: "warning",
                      timer: 4000,
                      showCancelButton: false,
                      showConfirmButton: false,
                      reverseButtons: true,
                    })
                    .then()
                  newProductData = null
                  selectedFidelity = null
                }
              } else {
                onMessageDialog("warning")
                  .fire({
                    title: "Não foi possível aplicar esta promoção",
                    icon: "warning",
                    timer: 4000,
                    showCancelButton: false,
                    showConfirmButton: false,
                    reverseButtons: true,
                  })
                  .then()
                newProductData = null
                selectedFidelity = null
              }
            }

            if (!!productDataBeverages && productDataBeverages.length > 0) {
              for (const beverage of productDataBeverages) {
                const promotionalBeverage = beverages.find(({ id_beverage }) => beverage.id_beverage === id_beverage)
                if (!!promotionalBeverage && !!newBeverageData) {
                  newBeverageData.push({
                    ...promotionalBeverage,
                    amount: 1,
                    val_price: 0,
                    id_fidelity: fidelity.id_fidelity,
                    uuid_fidelity: fidelity.uuid_fidelity,
                  })
                } else {
                  onMessageDialog("warning")
                    .fire({
                      title: "Não foi possível aplicar esta promoção",
                      icon: "warning",
                      timer: 4000,
                      showCancelButton: false,
                      showConfirmButton: false,
                      reverseButtons: true,
                    })
                    .then()
                  selectedFidelity = null
                  newBeverageData = null
                }
              }
            }
          }

          // console.log("newProductData", 5, newProductData)
          // console.log("newBeverageData", 5, newBeverageData)
          //Tratando as bebidas da fidelidade
          if (!!newProductData) {
            currentOrderRef.current.products.push(newProductData)
          }
          if (!!newBeverageData) {
            for (const beverage of newBeverageData) {
              currentOrderRef.current.beverages.push({ ...beverage })
            }
          }

          break
        }

        case "coupon": {
          const benefitToApply = benefits.find(({ id_benefit }) => id_benefit === fidelity.prize_benefit)

          if (benefitToApply) {
            const customer = user
            const data = {
              customerUid: customer.uuid_customer,
              benefitCode: benefitToApply.code_benefit,
              origin: "attendant",
            }
            const responseBenefit = await BenefitController.validateBenefit(data)

            if (responseBenefit.status === 200) {
              // Calcula o valor do cupom
              const val_benefit = getBenefitValue(currentOrderRef.current, responseBenefit.data)
              currentOrderRef.current = {
                ...currentOrderRef.current,
                benefits: [{ ...responseBenefit.data, id_fidelity: fidelity.id_fidelity, uuid_fidelity: fidelity.uuid_fidelity }],
                val_benefit,
              }

              // Calcula o novo total do combo
              const [total, subTotal] = getTotalOrder(currentOrderRef.current, userSelectedAddress?.deliveryFee)
              currentOrderRef.current = {
                ...currentOrderRef.current,
                id_fidelity: fidelity.id_fidelity,
                ds_discount: (currentOrderRef.current?.ds_discount || "") + `Programa de fidelidade: ${fidelity.nm_fidelity}`,
                total,
                subTotal,
              }
            } else {
              selectedFidelity = null
              // Message.info("Cupom não pode ser aplicado no carrinho")
            }
          }
          break
        }

        case "discount": {
          const { prize_discount, prize_discount_type: percentDiscount } = fidelity
          const newDiscount = parseFloat(currentOrderRef.current.discount) + parseFloat(prize_discount)

          currentOrderRef.current = {
            ...currentOrderRef.current,
            id_fidelity: fidelity.id_fidelity,
            uuid_fidelity: fidelity.uuid_fidelity,
            discount: newDiscount >= 0 ? newDiscount : 0,
            discountType: percentDiscount ? "%" : "R$",
            ds_discount: (currentOrderRef.current?.ds_discount || "") + ` Programa de fidelidade: ${fidelity.nm_fidelity}`,
          }

          break
        }
        default: {
          break
        }
      }

      // console.log("currentOrderRef.current", 2, currentOrderRef.current)
      const [total, subTotal] = getTotalOrder(currentOrderRef.current, userSelectedAddress?.deliveryFee)
      currentOrderRef.current = { ...currentOrderRef.current, total, subTotal }
      selectedPromotionRef.current = fidelity.uuid_fidelity
      setSelectedPromotion(fidelity.uuid_fidelity)
      const newOrder = {
        ...currentOrderRef.current,
        total,
        subTotal,
        id_fidelity: fidelity.id_fidelity,
        uuid_fidelity: fidelity.uuid_fidelity,
        fidelity,
      }
      dontRefreshRef.current = true
      setOrder(newOrder)
      setLoading(false)
      if (!!selectedPromotionRef.current) setModalBenefitFinder(false)
    }
  }

  //Remove o cupom do order
  const removeBenefitFromOrder = async (benefitUid) => {
    let currentBenefit = null
    if (benefitUid !== "all") {
      currentBenefit = currentOrderRef.current.benefits.find(({ uuid_benefit }) => uuid_benefit !== benefitUid)
    } else {
      currentBenefit = currentOrderRef.current.benefits[0]
    }
    // console.log("currentOrderRef.current", currentOrderRef.current)
    // console.log("currentBenefit", currentBenefit)
    if (!!currentBenefit) {
      switch (currentBenefit.tp_benefit) {
        //Removendo cupons de descontos
        case 0: {
          if (currentBenefit.tp_target === "CART") {
            currentOrderRef.current = {
              ...currentOrderRef.current,
              benefits: [],
            }
            delete currentOrderRef.current.id_benefit
            delete currentOrderRef.current.ds_discount
            return true
          }

          if (currentBenefit.tp_target === "SUBTOTAL") {
            currentOrderRef.current = {
              ...currentOrderRef.current,
              benefits: [],
            }
            delete currentOrderRef.current.id_benefit
            delete currentOrderRef.current.ds_discount
            return true
          }

          if (currentBenefit.tp_target === "ITEM") {
            currentOrderRef.current = {
              ...currentOrderRef.current,
              benefits: [],
            }
            for (let index = 0; index < currentOrderRef.current.products.length; index++) {
              if (currentOrderRef.current.products[index].id_benefit === currentBenefit.id_benefit) {
                delete currentOrderRef.current.products[index].ds_obs
                delete currentOrderRef.current.products[index].id_benefit
              }
            }
            delete currentOrderRef.current.id_benefit
            delete currentOrderRef.current.id_benefit_product_index
            return true

            // const productIndexWIthBenefit = currentOrderRef.current.products.findIndex((product) => product.id_benefit === currentBenefit.id_benefit)
            // if (productIndexWIthBenefit !== -1) {
            //   currentOrderRef.current = {
            //     ...currentOrderRef.current,
            //     benefits: [],
            //   }
            //   delete currentOrderRef.current.products[productIndexWIthBenefit].ds_obs
            //   delete currentOrderRef.current.products[productIndexWIthBenefit].id_benefit
            //   delete currentOrderRef.current.id_benefit
            //   delete currentOrderRef.current.id_benefit_product_index
            //   return true
            // }
          }

          if (currentBenefit.tp_target === "DELIVERY-FEE") {
            await onChangeUserSelectedAddress({ ...userSelectedAddress })
            currentOrderRef.current = {
              ...currentOrderRef.current,
              benefits: [],
            }
            delete currentOrderRef.current.id_benefit
            delete currentOrderRef.current.ds_discount
            return true
          }
          break
        }
        //Removendo os cupons de brinde
        case 1: {
          // tp_gift_type
          // 0 Brinde produto
          // 1 Brinde entrega
          if (currentBenefit.tp_gift_type === 0) {
            //Removendo os produtos vinculados ao programa de fidelidade
            const benefitProductIndex = currentOrderRef.current.products.findIndex(({ uuid_benefit }) => uuid_benefit === currentBenefit.uuid_benefit)
            if (benefitProductIndex !== -1) {
              currentOrderRef.current.products.splice(benefitProductIndex, 1)
            }

            //Removendo as bebidas vinculadas ao programa de fidelidade
            const benefitBeverageIndex = currentOrderRef.current.beverages.findIndex(
              ({ uuid_benefit }) => uuid_benefit === currentBenefit.uuid_benefit
            )
            if (benefitBeverageIndex !== -1) {
              currentOrderRef.current.beverages.splice(benefitBeverageIndex, 1)
            }
            currentOrderRef.current = {
              ...currentOrderRef.current,
              benefits: [],
            }
            delete currentOrderRef.current.id_benefit
            delete currentOrderRef.current.ds_discount
            return true
          } else if (currentBenefit.tp_gift_type === 1) {
            await onChangeUserSelectedAddress({ ...userSelectedAddress })
            currentOrderRef.current = {
              ...currentOrderRef.current,
              benefits: [],
            }
            delete currentOrderRef.current.id_benefit
            delete currentOrderRef.current.ds_discount
            return true
          }

          break
        }
        default: {
          return false
        }
      }
    } else {
      return true
    }
    return false
  }

  //Remove a campanha de fidelidade do order
  const removeFidelityFromOrder = async (fidelityUid) => {
    if (fidelityUid !== "all") {
      try {
        //Removendo os produtos vinculados ao programa de fidelidade
        const fidelityProductIndex = currentOrderRef.current.products.findIndex(({ uuid_fidelity }) => uuid_fidelity === fidelityUid)
        if (fidelityProductIndex !== -1) {
          currentOrderRef.current.products.splice(fidelityProductIndex, 1)
        }

        //Removendo as bebidas vinculadas ao programa de fidelidade
        const fidelityBeverageIndex = currentOrderRef.current.beverages.findIndex(({ uuid_fidelity }) => uuid_fidelity === fidelityUid)
        if (fidelityBeverageIndex !== -1) {
          currentOrderRef.current.beverages.splice(fidelityBeverageIndex, 1)
        }

        //Removendo os cupons vinculados ao programa de fidelidade
        const fidelityBenefitIndex = currentOrderRef.current.benefits.findIndex(({ uuid_fidelity }) => uuid_fidelity === fidelityUid)
        if (fidelityBenefitIndex !== -1) {
          const val_benefit = getBenefitValue(currentOrderRef.current, currentOrderRef.current.benefits[0])
          // const val_benefit = getBenefitValue(currentOrderRef.current, benefits[0])
          const newDiscount =
            parseFloat(currentOrderRef.current.discount) -
            (currentOrderRef.current.discountType === "R$" && currentOrderRef.current.benefits[0].tp_discount_type === 1
              ? parseFloat(currentOrderRef.current.benefits[0].val_benefit)
              : parseFloat(val_benefit))

          currentOrderRef.current = {
            ...currentOrderRef.current,
            val_benefit: 0,
            discount: newDiscount >= 0 ? newDiscount : 0,
            ds_discount: "",
          }
          currentOrderRef.current.benefits.splice(fidelityBenefitIndex, 1)
        }

        //Removendo os descontos vinculados ao programa de fidelidade
        const fidelityDiscountData = fidelities.find(({ uuid_fidelity }) => uuid_fidelity === fidelityUid)
        if (!!fidelityDiscountData) {
          if (currentOrderRef.current.uuid_fidelity === fidelityDiscountData.uuid_fidelity) {
            const { prize_discount } = fidelityDiscountData
            const newDiscount = parseFloat(currentOrderRef.current.discount) - parseFloat(prize_discount)
            currentOrderRef.current = {
              ...currentOrderRef.current,
              discount: newDiscount >= 0 ? newDiscount : 0,
              ds_discount: "",
            }
          }
        }

        const [total, subTotal] = getTotalOrder(currentOrderRef.current, userSelectedAddress?.deliveryFee)
        delete currentOrderRef.current.fidelity
        // Atualizando o referencias
        selectedPromotionRef.current = null
        currentOrderRef.current = { ...currentOrderRef.current, total, subTotal }
        return true
      } catch (error) {
        console.error("Falha ao remover fidelidade: ", error)
        return false
      }
    } else {
      try {
        //Removendo os produtos vinculados ao programa de fidelidade
        const fidelityProductIndex = currentOrderRef.current.products.findIndex(({ uuid_fidelity = null }) => !!uuid_fidelity)
        if (fidelityProductIndex !== -1) {
          currentOrderRef.current.products.splice(fidelityProductIndex, 1)
        }

        //Removendo as bebidas vinculadas ao programa de fidelidade
        const fidelityBeverageIndex = currentOrderRef.current.beverages.findIndex(({ uuid_fidelity = null }) => !!uuid_fidelity)
        if (fidelityBeverageIndex !== -1) {
          currentOrderRef.current.beverages.splice(fidelityBeverageIndex, 1)
        }

        //Removendo os cupons vinculados ao programa de fidelidade
        const fidelityBenefitIndex = currentOrderRef.current.benefits.findIndex(({ uuid_fidelity = null }) => !!uuid_fidelity)
        if (fidelityBenefitIndex !== -1) {
          const val_benefit = getBenefitValue(currentOrderRef.current, currentOrderRef.current.benefits[0])
          // const val_benefit = getBenefitValue(currentOrderRef.current, benefits[0])
          const newDiscount =
            parseFloat(currentOrderRef.current.discount) -
            (currentOrderRef.current.discountType === "R$" && currentOrderRef.current.benefits[0].tp_discount_type === 1
              ? parseFloat(currentOrderRef.current.benefits[0].val_benefit)
              : parseFloat(val_benefit))

          currentOrderRef.current = {
            ...currentOrderRef.current,
            val_benefit: 0,
            discount: newDiscount >= 0 ? newDiscount : 0,
            ds_discount: "",
          }
          currentOrderRef.current.benefits.splice(fidelityBenefitIndex, 1)
        }

        //Removendo os descontos vinculados ao programa de fidelidade
        if (!!currentOrderRef?.current?.uuid_fidelity) {
          const fidelityDiscountData = fidelities.find(({ uuid_fidelity }) => uuid_fidelity === currentOrderRef?.current?.uuid_fidelity)
          if (!!fidelityDiscountData) {
            const { prize_discount } = fidelityDiscountData
            const newDiscount = parseFloat(currentOrderRef?.current?.discount) - parseFloat(prize_discount)
            currentOrderRef.current = {
              ...currentOrderRef.current,
              discount: newDiscount >= 0 ? newDiscount : 0,
              ds_discount: "",
            }
          }
        }

        const [total, subTotal] = getTotalOrder(currentOrderRef.current, userSelectedAddress?.deliveryFee)
        delete currentOrderRef.current.fidelity
        delete currentOrderRef.current.id_fidelity
        delete currentOrderRef.current.uuid_fidelity
        // Atualizando o referencias
        selectedPromotionRef.current = null
        currentOrderRef.current = { ...currentOrderRef.current, total, subTotal }
        return true
      } catch (error) {
        console.error("Falha ao remover fidelidade: ", error)
        return false
      }
    }
  }

  const verifyBenefit = () => {
    const localUser = getLocalUser()

    if (!localUser) {
      onMessageDialog("warning")
        .fire({
          title: `Realize login para validar seu cupom!`,
          icon: "warning",
          timer: 1000,
          showCancelButton: false,
          showConfirmButton: false,
          reverseButtons: true,
        })
        .then(() => {
          setModalSignIn({ status: true })
        })
    } else {
      setLoadingApply(true)
      BenefitController.validateBenefit({
        customerUid: localUser.uuid_customer,
        benefitCode,
        origin: "website",
      })
        .then((responseBenefit) => {
          if (responseBenefit.status === 500) {
            onMessageDialog("error")
              .fire({
                title: "Falha ao consultar os dados do cupom.",
                icon: "error",
                timer: 4000,
                showCancelButton: false,
                showConfirmButton: false,
              })
              .then()
            setLoadingApply(false)
          } else if (responseBenefit.status === 204) {
            onMessageDialog("warning")
              .fire({
                title: "Nenhum cupom localizado para o código informado.",
                icon: "info",
                timer: 4000,
                showCancelButton: false,
                showConfirmButton: false,
              })
              .then()
            setLoadingApply(false)
          } else if (responseBenefit.status === 202) {
            onMessageDialog("warning")
              .fire({
                title: responseBenefit.message,
                icon: "info",
                timer: 4000,
                showCancelButton: false,
                showConfirmButton: false,
              })
              .then()
            setLoadingApply(false)
          } else if (responseBenefit.status === 200) {
            applyBenefitPromotion(responseBenefit.data).then()
          }
        })
        .catch((error) => console.error("Falha ao verificar o cupom:", error))
      // .finally(() => {
      //   setLoadingApply(false)
      // })
    }
  }

  const getPromotionName = () => {
    let name = ""
    const selectedPromotionBenefit = promotions.find(({ uuid_benefit }) => uuid_benefit === selectedPromotion)
    const selectedPromotionFidelity = promotions.find(({ uuid_fidelity }) => uuid_fidelity === selectedPromotion)

    if (!!selectedPromotionBenefit) {
      name = selectedPromotionBenefit.nm_benefit
    } else if (!!selectedPromotionFidelity) {
      name = selectedPromotionFidelity.nm_fidelity
    }

    return name
  }
  // console.log("order", order)
  return (
    <div
      className="benefit-item"
      onClick={() => {
        setModalBenefitFinder(true)
      }}
    >
      <div className="benefit-item--left">
        {(() => {
          if (!!selectedPromotion) {
            const getIcon = promotions.find((promotion) => {
              if (!!promotion.uuid_benefit) return promotion.uuid_benefit === selectedPromotion
              if (!!promotion.uuid_fidelity) return promotion.uuid_fidelity === selectedPromotion
              return false
            })
            if (!!getIcon) {
              return <FontAwesomeIcon icon={getIcon.icon} />
            } else {
              return <FontAwesomeIcon icon={"ticket"} />
            }
          } else {
            return <FontAwesomeIcon icon={"ticket"} />
          }
        })()}
        {/*<img alt="" src={couponIcon} />*/}
      </div>
      <div className="benefit-item--center">
        {!!selectedPromotion ? (
          <>
            <span className="benefit-item--center--title">{getPromotionName()}</span>
            {/*<span className="benefit-item--center--desc">{getPromotionDescription()}</span>*/}
          </>
        ) : (
          <>
            <span className="benefit-item--center--title">Cupons e Promoções</span>
            <span className="benefit-item--center--desc">{promotions.length} disponíveis</span>
          </>
        )}
      </div>
      <div className="benefit-item--right">
        {!!selectedPromotion ? (
          <span>Alterar</span>
        ) : (
          <span>
            Adicionar <FontAwesomeIcon icon={"plus"} />
          </span>
        )}
      </div>

      <BenefitFinder
        status={modalBenefitFinder}
        onApplyPromotion={onApplyPromotion}
        selectedPromotion={selectedPromotion}
        promotions={promotions}
        loadingApply={loadingApply}
        returnMenu={() => {
          setModalBenefitFinder(false)
        }}
        benefitCode={benefitCode}
        setBenefitCode={setBenefitCode}
        verifyBenefit={verifyBenefit}
      />
    </div>
  )
}

export default BenefitItem

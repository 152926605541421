import React from "react"

import { Label, Button } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./NumberControl.scss"

const NumberControl = ({
  title = "",
  number = 0,
  direction = "vertical",
  backgroundColor = "#1B1E21",
  color = "#FFFFFF",
  minimum = null,
  maximum = null,
  limit = 0,
  allowZero = true,
  allowNegative = false,
  className = "",
  onChangeNumber,
  selectedOptionals,
  maxOptions,
}) => {
  // const [count, setCount] = React.useState(number)

  // React.useEffect(() => {
  //   // setCount(number)
  // }, [number])

  const setUp = () => {
    const newCount = number + 1

    if (limit > 0 && newCount > limit) {
      onChangeNumber(newCount, "up")
    } else {
      if (maximum !== null && newCount >= maximum) {
        onChangeNumber(newCount, "up")
      } else {
        // setCount(newCount)
        onChangeNumber(newCount, "up")
      }
    }
  }

  const setDown = () => {
    const newCount = number - 1

    if ((newCount === 0 && allowZero) || (newCount < 0 && allowNegative)) {
      // setCount(newCount)
      onChangeNumber(newCount, "down")
    } else if (newCount > 0) {
      if (minimum !== null && newCount <= minimum) {
        onChangeNumber(newCount, "down")
      } else {
        // setCount(newCount)
        onChangeNumber(newCount, "down")
      }
    }
  }

  if (direction === "vertical") {
    return (
      <div className={"number-control " + className}>
        <Button className={"number-control--body " + direction}>
          <Label onClick={setUp} className="number-control--body--button-icon">
            <FontAwesomeIcon icon="plus" />
          </Label>
          <Label
            className="number-control--body--button-number"
          //  style={{ backgroundColor, color }}
          >
            {number}
          </Label>
          <Label onClick={setDown} className="number-control--body--button-icon">
            <FontAwesomeIcon icon="minus" />
          </Label>
        </Button>
      </div>
    )
  } else {
    return (
      <div className={"number-control " + className}>
        <Button className={"number-control--body " + direction}>
          <Label onClick={number > 0 && allowNegative === false ? setDown : () => { }} className="number-control--body--button-icon">
            <FontAwesomeIcon icon="minus" className={number > 0 && allowNegative === false ? "" : "disabled"} />
          </Label>
          <Label
            className="number-control--body--button-number"
          // style={{ backgroundColor, color }}
          >
            {number}
          </Label>

          <Label onClick={setUp} className="number-control--body--button-icon">
            <FontAwesomeIcon icon="plus" className={number >= maxOptions || (limit > 0 && selectedOptionals === limit) ? "disabled" : ""} />
          </Label>
        </Button>
      </div>
    )
  }
}

export default NumberControl

import React from "react"
import PropTypes from "prop-types"
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import CollapseItem from "../../components/CollapseItem"

import "./ModalIngredients.scss"

const ModalIngredients = (props) => {

  const {
    status,
    title,
    content,
    flavoursAmount,
    selectedComboFlavourNumber,
    onClose,
    onChangeRemoveIngredient,
    onChangeOptional,
    onChangeAmountOptional,
  } = props

  const getMaxOptionals = () => {
    const { flavour } = content

    if (!!flavour.sizes) {
      return flavour.sizes.find((eachSize) => eachSize.uuid_size === content.size.uuid_size).qty_optionals
    } else if (!!flavour.size) {
      return content.flavour.size.qty_optionals
    }

    return 1
  }
  return (
    <Modal isOpen={status} centered toggle={onClose} size={"lg"} className={"modal-ingredients"}>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody>
        {/* Remoção de ingredientes */}
        {(() => {
          if (!!content) {
            const {
              flavour: { ingredients = [], id_flavour, restrictions = [] },
              nro_item,
              itemIndex,
              size,
            } = content
            const ingredientsEnabled = ingredients.filter(
              ({ ingredient_flavour: { st_website }, sizes }) =>
                st_website && sizes.find(({ id_size, ingredient_size }) => id_size === size?.id_size && ingredient_size?.st_website)
            )
            if (ingredientsEnabled.length > 0) {
              return (
                <CollapseItem
                  type="ingredient"
                  status={true}
                  size={size}
                  title="Deseja remover algum ingrediente?"
                  subtitle="Selecione os ingredientes que você NÃO quer no seu produto"
                  content={ingredientsEnabled}
                  selected={restrictions}
                  onClick={(restriction) => {
                    onChangeRemoveIngredient(restriction, id_flavour, nro_item, itemIndex)
                  }}
                />
              )
            } else if (!!content.flavour.optionals && content.flavour.optionals.length > 0) {
              return <p>Sabor não permite remoção de ingredientes</p>
            }
          }
        })()}

        {/* Adicionar ingredientes opcionais ao sabor */}
        {(() => {
          if (!!content) {
            let {
              size,
              nro_item,
              flavour: { id_flavour, optionals = [], additions = [] },
              itemIndex,
            } = content

            // Verificando a quantidade de sabores selecionada
            let flavourQty = flavoursAmount
            if (selectedComboFlavourNumber.length > 0) {
              const comboFlavourNumber = selectedComboFlavourNumber.find((comboFlavourNumber) => comboFlavourNumber.nro_item === nro_item)
              if (!!comboFlavourNumber) {
                flavourQty = comboFlavourNumber.number
              }
            }
            const optionalsEnabled = optionals.filter(
              ({ optional_flavour: { st_website }, sizes }) =>
                st_website && sizes.find(({ id_size, ingredient_size }) => id_size === size?.id_size && ingredient_size?.st_website)
            )

            if (optionalsEnabled.length > 0) {
              return (
                <CollapseItem
                  maxOptionals={getMaxOptionals()}
                  type="optional"
                  status={true}
                  selectedFlavour={content.flavour}
                  title="Deseja acrescentar algo?"
                  subtitle="Selecione os ingredientes que você quer a mais"
                  content={optionalsEnabled}
                  selected={additions}
                  size={size}
                  selectedFlavourNumber={flavourQty}
                  onClick={(optional) => {
                    onChangeOptional(optional, id_flavour, nro_item, itemIndex)
                  }}
                  onChangeAmount={(amount, direction, type, optional) => {
                    onChangeAmountOptional(amount, direction, type, optional, id_flavour, nro_item, itemIndex)
                  }}
                />
              )
            } else if (!!content.flavour.ingredients && content.flavour.ingredients.length > 0) {
              return <p>Sabor não permite acréscimo de ingredientes</p>
            }
          }
        })()}

        {(() => {
          if (!!content) {
            if (
              (!!content.flavour.ingredients && content.flavour.ingredients.length) === 0 &&
              !!content.flavour.optionals && content.flavour.optionals.length === 0
            ) {
              return <p>Sabor não permite remoção e acréscimo de ingredientes</p>
            }
          }
        })()}
      </ModalBody>
      <ModalFooter>
        <Label onClick={onClose} className="footer-button-right">
          Continuar
        </Label>
      </ModalFooter>
    </Modal>
  )
}

ModalIngredients.propTypes = {
  content: PropTypes.object,
}

export default ModalIngredients

import React from "react"

//External Libs
import { Label, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//Helpers
import GeneralTools, { formatBoolean, getUrlAWSFile, getUrlAWSManagerFile, sortList } from "../helpers/GeneralTools"
import { getFidelityProgress } from "../helpers/UserTools"

//Contexts
import SettingsContext from "../contexts/SettingsContext"
import CompanyContext from "../contexts/CompanyContext"
import MenuContext from "../contexts/MenuContext"
import UserContext from "../contexts/UserContext"
import TabContext from "../contexts/TabContext"

//Styles
import "./MainOptions.scss"
import { getSessionItem } from "../helpers/StorageTools"

const MainOptions = ({ type }) => {
  const { company } = React.useContext(CompanyContext)
  const { setTabSelected, moveToFidelity } = React.useContext(TabContext)
  const { setModalInitialAddress, setModalCompanies } = React.useContext(MenuContext)
  const { settings, modality, setModality, fidelities } = React.useContext(SettingsContext)
  const { userSelectedAddress, user } = React.useContext(UserContext)
  const [aboutPopup, setAboutPopup] = React.useState(false)
  const [modalityPopup, setModalityPopup] = React.useState(false)

  const [activeTab, setActiveTab] = React.useState("about")
  const [tabs, setTabs] = React.useState({ general: "active", history: "" })

  const defaultDays = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"]

  const getDeliveryTime = () => {
    let actualDeliveryTime =
      modality === 1 ? userSelectedAddress.deliveryTime : modality === 2 ? Number(settings.pickup_time) : Number(settings.indoor_time)

    if (!!actualDeliveryTime) {
      if (!!settings.site_tolerance_time && Number(settings.site_tolerance_time) >= 1) {
        return `${actualDeliveryTime} - ${actualDeliveryTime + Number(settings.site_tolerance_time)} min`
      } else {
        return `${actualDeliveryTime} min`
      }
    } else if (!!settings.delivery_time) {
      if (!!settings.site_tolerance_time && Number(settings.site_tolerance_time) >= 1) {
        return `${settings.delivery_time} - ${Number(settings.delivery_time) + Number(settings.site_tolerance_time)} min`
      } else {
        return `${settings.delivery_time} min`
      }
    }
    return "-"
  }

  const getDeliveryFee = () => {
    if (userSelectedAddress.deliveryFee > 0) {
      return GeneralTools.formatMoney(userSelectedAddress.deliveryFee)
    }
    return "Grátis"
  }

  const changeModalityToComeGet = () => {
    setModality(2)
    setModalityPopup(!modalityPopup)
  }

  const changeModalityToIndoor = () => {
    setModality(3)
    setModalityPopup(!modalityPopup)
  }

  const getPaymentsOrganized = () => {
    // console.log("company", company)
    let result = []
    if (!!company && !!company.company_payment_methods && !!company.company_payment_categories) {
      company.company_payment_categories.forEach((category) => {
        const alreadyHasDefault = result.find((r) => r.payment_name === "Outros")
        if (category.subcategory_payment_methods.length === 0 && !alreadyHasDefault) {
          const payments = company.company_payment_methods.filter(({ category_association: { fk_id_subcategory_method } }) => {
            return fk_id_subcategory_method === null
          })
          if (payments.length > 0) {
            result.push({
              payment_name: "Outros",
              payments,
            })
          }
        }
        category.subcategory_payment_methods.forEach((subcategory) => {
          const payments = company.company_payment_methods.filter(({ category_association: { fk_id_subcategory_method } }) => {
            return fk_id_subcategory_method === subcategory.id_subcategory_payment_methods
          })
          if (payments.length > 0) {
            result.push({
              payment_name: subcategory.nm_subcategory_payment_methods,
              payments,
            })
          }
        })
      })
    }
    return result
  }

  const getCompanyAddressFormated = () => {
    let firstPart = "Problemas para localizar endereço da loja"
    let secondPart = ""
    let thirdPart = ""

    if (company?.company_address?.length > 0) {
      const address = company.company_address.find(({ st_primary }) => st_primary)
      firstPart = `${address.street.nm_street}${address.num_address > 0 ? `, ${address.num_address}` : ""} - ${
        address.street.neighbourhood.nm_neighbourhood
      }`
      secondPart = `${address.street.neighbourhood.city.nm_city}, ${address.street.neighbourhood.city.state.nm_state}`
      thirdPart = `CEP: ${address.street.zipcode}`
    }

    return [{ firstPart, secondPart, thirdPart }]
  }

  const formatedHour = (times, weekDay, stDay) => {
    const dateNow = new Date()
    let result = "24 horas"
    if (times.length > 0) {
      const { start_hour, start_minute, end_hour, end_minute } = times[0]

      if (start_hour === 0 && start_minute === 0 && end_hour === 23 && end_minute === 59) {
        result = "Aberto 24 horas"
      } else {
        result = `${start_hour}:${start_minute < 10 ? "0" + start_minute : start_minute} as ${end_hour}:${
          end_minute < 10 ? "0" + end_minute : end_minute
        }`
      }
    }

    if (stDay === false) {
      result = "Fechado"
    }

    return (
      <>
        <p className={dateNow.getDay() === weekDay ? "today" : ""}>{defaultDays[weekDay]}</p>
        <p className={dateNow.getDay() === weekDay ? "today" : ""}>{result}</p>
      </>
    )
  }

  return (
    <div className={"main-options " + (type === "largeDevices" ? "main-options-large-devices" : "")}>
      {/* Pedido mínimo */}
      <div className="main-option-info-order">
        {!!settings.minimal_order_price && GeneralTools.parseFloat(settings.minimal_order_price) > 0 ? (
          <Label className={"color-store-closed " + (type === "largeDevices" ? "option-title" : "option-title-main")}>
            Pedido mínimo {GeneralTools.formatMoney(GeneralTools.parseFloat(settings.minimal_order_price))}
          </Label>
        ) : (
          ""
        )}
      </div>

      {/*Campanha de fidelidade*/}
      {!!user && fidelities.length > 0 ? (
        <div className={"main-otion--fidelities"}>
          <div className={"main-otion--fidelities--left"}>
            <FontAwesomeIcon icon={"percentage"} />
          </div>
          <div className={"main-otion--fidelities--right"}>
            <div className={"main-otion--fidelities--right--header"}>
              <span>Programas de fidelidade</span>
            </div>
            <div className={"main-otion--fidelities--right--body"}>
              {(() => {
                // console.log("user", user)
                // console.log("settings", settings)
                // console.log("fidelities", fidelities)
                let fidelitiesToRescue = []
                let fidelitiesInProgress = []
                let localFidelitiesUsed = getSessionItem("fidelitiesUsed", true)
                let fidelityUsed = false
                // console.log("fidelities", fidelities)
                for (const fidelity of fidelities) {
                  if (!!localFidelitiesUsed) {
                    fidelityUsed = localFidelitiesUsed.find((fidelityId) => fidelityId === fidelity.id_fidelity)
                  }
                  // console.log("mainOptions - fidelity", fidelity)
                  const { actual, total, progress, readyToFinish } = getFidelityProgress(fidelity, user)
                  if (readyToFinish && !fidelityUsed) {
                    fidelitiesToRescue.push({ ...fidelity, actual, total, progress, readyToFinish })
                  } else {
                    fidelitiesInProgress.push({ ...fidelity, actual, total, progress, readyToFinish })
                  }
                }
                // console.log("fidelitiesToRescue", fidelitiesToRescue)
                // console.log("fidelitiesInProgress", fidelitiesInProgress)

                if (fidelitiesToRescue.length === 1) {
                  //Pronto para resgatar essa fidelidade
                  return (
                    <div className={"main-otion--fidelities--right--body--item"}>
                      <span style={{ color: "#3bbd0d" }}>{`Promoção ${fidelitiesToRescue[0].nm_fidelity} pronta para resgate!`}</span>
                    </div>
                  )
                } else if (fidelitiesToRescue.length > 1) {
                  //Existe mais de 1 fidelidade pronta para resgatar
                  //nesse caso cliente vai ser direcionado para a tela de fidelidades
                  return (
                    <div
                      className={"main-otion--fidelities--right--body--item"}
                      onClick={() => {
                        moveToFidelity()
                        setTabSelected("profile")
                      }}
                    >
                      <span style={{ color: "#3bbd0d" }}>{`Você possui ${fidelitiesToRescue.length} prontas para resgate!`}</span>
                    </div>
                  )
                } else if (fidelitiesInProgress.length === 1) {
                  //Caso estabelecimento tenha somente uma fidelidade cadastrada
                  //Deverá mostrar apenas o progresso dela
                  return (
                    <div
                      className={"main-otion--fidelities--right--body--item"}
                      onClick={() => {
                        moveToFidelity()
                        setTabSelected("profile")
                      }}
                    >
                      <span style={{ color: "#000" }}>{`Promoção ${fidelitiesInProgress[0]?.nm_fidelity} em andamento.`}</span>
                    </div>
                  )
                } else if (fidelitiesInProgress.length > 1) {
                  //Existe mais de 1 fidelidade cadastrada para mostrar
                  //nesse caso cliente vai ser direcionado para a tela de fidelidades

                  return (
                    <div
                      className={"main-otion--fidelities--right--body--item"}
                      onClick={() => {
                        moveToFidelity()
                        setTabSelected("profile")
                      }}
                    >
                      <span>{`Você está participando de  ${fidelitiesInProgress.length} promoções`}</span>
                    </div>
                  )
                }

                return ""
              })()}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* Dados de entrega */}
      <div onClick={() => setModalityPopup(!modalityPopup)} className={"main-option-container " + (type === "largeDevices" ? "divider" : "")}>
        <div className="main-option-container--icon">
          <FontAwesomeIcon className="main-option-container--icon--svg" icon={[3].includes(modality) ? "cutlery" : "location-dot"} />
        </div>
        <div className="main-option-container--desc">
          {(() => {
            if (company?.company_address && (userSelectedAddress?.nm_street || [3, 2].includes(modality))) {
              if (modality === 1) {
                return (
                  <>
                    <p className="main-option-container--desc--modality">Entregar em</p>
                    <p>
                      {userSelectedAddress?.street_type?.nm_type || "Rua"} {userSelectedAddress?.nm_street || "Não identificada"},{" "}
                      {userSelectedAddress?.customer_address?.num_address || "S/N"},{" "}
                      {userSelectedAddress?.neighbourhood?.nm_neighbourhood || "Sem Bairro"}
                    </p>
                  </>
                )
              } else if (modality === 2) {
                return (
                  <>
                    <p className="main-option-container--desc--modality">Retirar em</p>
                    <p>{`${company?.company_address[0]?.street?.nm_street}, ${Number(company?.company_address[0]?.num_address)}`}</p>
                  </>
                )
              } else if (modality === 3) {
                return (
                  <>
                    <p className="main-option-container--desc--modality">Consumir no local</p>
                    <p>{`${company?.company_address[0]?.street?.nm_street}, ${Number(company?.company_address[0]?.num_address)}`}</p>
                  </>
                )
              }
            } else {
              return (
                <div className="main-option-container--desc--tax-info">
                  <p> Taxas e tempos de entrega</p>
                </div>
              )
            }
          })()}
        </div>
        <div className="main-option-container--info">
          {/*{userSelectedAddress?.deliveryTime || userSelectedAddress?.deliveryFee ? (*/}
          {/*  <p>{`Taxa: ${formatMoney(userSelectedAddress?.deliveryFee)}. Tempo: ${userSelectedAddress?.deliveryTime} minutos`}</p>*/}
          {/*) : (*/}
          {/*  ""*/}
          {/*)}*/}

          {!!userSelectedAddress || modality === 2 ? (
            !!company && modality === 1 ? (
              <>
                <p>{getDeliveryTime()}</p>
                <p>{getDeliveryFee()}</p>
              </>
            ) : (
              <>
                <p>{getDeliveryTime()}</p>
              </>
            )
          ) : modalityPopup ? (
            <FontAwesomeIcon className="main-option-container--info--svg" icon="chevron-down" />
          ) : (
            <FontAwesomeIcon className="main-option-container--info--svg" icon="chevron-left" />
          )}
        </div>
      </div>

      {/*Status do site aberto ou fechado*/}
      {!!company && company.st_website ? (
        <div className="main-option--status-company company-opened">
          <label className="color-store-opened">Aberto</label>
        </div>
      ) : (
        <div className="main-option--status-company company-closed">
          <label className="color-store-closed">Fechado</label>
        </div>
      )}

      {/* Icone da loja */}
      <div className="main-option-image">
        {!!company.url_image && GeneralTools.parseBoolean(settings.site_display_logo) && (
          <div className="img-logo img-center">
            <img alt={`logo ${company.nm_company}`} src={getUrlAWSManagerFile(company.url_image)} />
          </div>
        )}
      </div>

      {/*Popup da forma que o cliente deseja receber o pedido*/}
      <div className={`main-options--modality-popup ${modalityPopup ? "active-modality-popup" : ""}`}>
        <span>Como deseja realizar o pedido?</span>

        {/*Verificando as modalitdes do estabelecimento*/}
        {(() => {
          if (!!company.company_modality) {
            return company.company_modality
              .sort((a, b) => sortList(a, b, "fk_id_modality"))
              .map(({ fk_id_modality: modalityId }, key) => {
                switch (modalityId) {
                  case 1:
                    return (
                      <div
                        key={key}
                        className="main-options--modality-popup--delivery"
                        onClick={() => {
                          setModalInitialAddress({ status: true, title: "", content: { source: "mainOptions" } })
                          setModality(1)
                          setModalityPopup(!modalityPopup)
                        }}
                      >
                        <div className="main-options--modality-popup--delivery--icon">
                          <FontAwesomeIcon icon="motorcycle" />
                        </div>
                        <div className="main-options--modality-popup--delivery--text">
                          <div className="main-options--modality-popup--delivery--text--top">
                            <p>Para entrega</p>
                          </div>
                          <div className="main-options--modality-popup--delivery--text--bottom">
                            <p>Nós levamos o seu pedido até sua casa</p>
                          </div>
                        </div>
                      </div>
                    )
                  case 2:
                    return (
                      <div
                        key={key}
                        className="main-options--modality-popup--takeaway"
                        onClick={() => {
                          changeModalityToComeGet()
                        }}
                      >
                        <div className="main-options--modality-popup--takeaway--icon">
                          <FontAwesomeIcon icon="store" />
                        </div>
                        <div className="main-options--modality-popup--takeaway--text">
                          <div className="main-options--modality-popup--takeaway--text--top">
                            <p>Para retirada</p>
                          </div>
                          <div className="main-options--modality-popup--takeaway--text--bottom">
                            <p>Você pode retirar na nossa loja!</p>
                          </div>
                        </div>
                      </div>
                    )
                  case 3:
                    if (formatBoolean(settings?.show_local_consumation)) {
                      return (
                        <div
                          key={key}
                          className="main-options--modality-popup--takeaway"
                          onClick={() => {
                            changeModalityToIndoor()
                          }}
                        >
                          <div className="main-options--modality-popup--takeaway--icon">
                            <FontAwesomeIcon icon="cutlery" />
                          </div>
                          <div className="main-options--modality-popup--takeaway--text">
                            <div className="main-options--modality-popup--takeaway--text--top">
                              <p>Consumo no local</p>
                            </div>
                            <div className="main-options--modality-popup--takeaway--text--bottom">
                              <p>Você pode consumir na nossa loja!</p>
                            </div>
                          </div>
                        </div>
                      )
                    }

                    return ""
                  default: {
                    return ""
                  }
                }
              })
          }
        })()}

        <div
          className="main-options--modality-popup--takeaway"
          onClick={() => {
            setModalityPopup(false)
            setModalCompanies({ status: true, content: [] })
          }}
        >
          <div className="main-options--modality-popup--takeaway--icon">
            <FontAwesomeIcon icon="tent-arrow-left-right" />
          </div>
          <div className="main-options--modality-popup--takeaway--text">
            <div className="main-options--modality-popup--takeaway--text--top">
              <p>Trocar de loja</p>
            </div>
            <div className="main-options--modality-popup--takeaway--text--bottom">
              <p>Você pode escolher entre as nossas lojas</p>
            </div>
          </div>
        </div>
      </div>

      {/*Controle de abrir e fechar o sobre a loja*/}
      <div
        className="main-option-about"
        onClick={() => {
          setAboutPopup(!aboutPopup)
        }}
      >
        <Label>Sobre a loja&nbsp;</Label>
        <FontAwesomeIcon className="main-option-about--icon--svg" icon={aboutPopup ? "chevron-down" : "chevron-right"} />
      </div>

      {/*Popup do sobre a loja*/}
      <div className={`main-options--about-popup ${aboutPopup ? "active-about-popup" : ""}`}>
        <Nav tabs>
          <NavItem className={activeTab === "about" ? "tab-item-selected" : "tab-item"}>
            <NavLink
              className={tabs.about}
              onClick={() => {
                setActiveTab("about")
                setTabs({ about: "active", history: "" })
              }}
            >
              Sobre
            </NavLink>
          </NavItem>
          <NavItem className={activeTab === "payment" ? "tab-item-selected" : "tab-item"}>
            <NavLink
              className={tabs.payment}
              onClick={() => {
                setActiveTab("payment")
                setTabs({ about: "", payment: "active" })
              }}
            >
              Pagamento
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab} className="main-options--about-popup--tab">
          <TabPane tabId="about">
            <div className="main-options--about-popup--tab--about">
              <div className="main-options--about-popup--tab--about--bio">
                <label>{!!settings.site_about_description ? settings.site_about_description : "Sem mais informações"}</label>
              </div>

              <div className="main-options--about-popup--tab--about--address">
                <strong>Endereço</strong>
                {getCompanyAddressFormated().map(({ firstPart, secondPart, thirdPart }, index) => {
                  return (
                    <div key={index} className="main-options--about-popup--tab--about--address--info">
                      <label>{firstPart}</label>
                      <label>{secondPart}</label>
                      <label>{thirdPart}</label>
                    </div>
                  )
                })}
              </div>

              <div className="main-options--about-popup--tab--about--working-period">
                <strong>Horário de Funcionamento</strong>

                {/* Fazer um map com os workingPeriods que vier do banco */}
                {!!company.company_working_periods &&
                  company.company_working_periods.map((eachday, index) => {
                    return (
                      <div key={index} className="main-options--about-popup--tab--about--working-period--day">
                        {formatedHour(eachday.working_period_times, eachday.day_of_week, eachday.st_day_of_week)}
                      </div>
                    )
                  })}
              </div>

              <div className="main-options--about-popup--tab--about--working-period">
                <strong>Versão: {process.env.REACT_APP_VERSION}</strong>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="payment">
            <div className="main-options--about-popup--tab--payment">
              {/*ESSA PARTE  VAI SER PARA QUANDO TIVER O INTEGRAÇÃO COM PAGAMENTO ONLINE
                <div className="main-options--about-popup--tab--payment--online">
                <strong>Pagamento pelo Site</strong>
              </div> */}
              <div className="main-options--about-popup--tab--payment--delivery-man">
                <strong>Pagamento na Entrega</strong>

                {getPaymentsOrganized().map((category, index) => {
                  return (
                    <div key={index}>
                      <Label>{category.payment_name}</Label>
                      <div className="main-options--about-popup--tab--payment--delivery-man--category">
                        {category.payments.map((payment, index) => {
                          return (
                            <div key={index} className="main-options--about-popup--tab--payment--delivery-man--category--data">
                              {payment.url_image !== null && payment.url_image !== "" ? (
                                <img src={getUrlAWSFile(payment.url_image)} alt={payment.nm_payment_method} />
                              ) : (
                                <FontAwesomeIcon icon="money-bill" />
                              )}

                              <Label>{payment.nm_payment_method}</Label>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </div>
  )
}

export default MainOptions

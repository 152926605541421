import React from "react"
// External Libs
import { Label, Spinner } from "reactstrap"
import moment from "moment"
import "moment/locale/pt-br"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component"
import "mapbox-gl/dist/mapbox-gl.css"
import { Map, Marker } from "react-map-gl"

// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
import mapboxgl from "mapbox-gl"

// Images
import FakeMap from "../../assets/img/general/mapa-fake.png"
import deliverymanMarker from "../../assets/img/general/helmet.png"
import homeMarker from "../../assets/img/general/homemarker.png"

// Helpers
import { formatBoolean, formatFloat, getFormatDate, setNumber } from "../../helpers/GeneralTools"

// Contexts
import SettingsContext from "../../contexts/SettingsContext"
import SocketContext from "../../contexts/SocketContext"
import CompanyContext from "../../contexts/CompanyContext"

import "./OrderProgress.scss"
import UserContext from "../../contexts/UserContext"
import { Toaster } from "react-hot-toast"

const OrderProgress = ({ status, returnMenu, content }) => {
  const { settings, currentStep, setCurrentStep } = React.useContext(SettingsContext)
  const { socket } = React.useContext(SocketContext)
  const { company } = React.useContext(CompanyContext)
  const { user } = React.useContext(UserContext)

  const [currentProgressStep, setCurrentProgressStep] = React.useState({ step: 0, date: "hh:mm", orderId: 0 })

  // Estados da rotina de rastreio do motoboy
  const [orderFinished, setOrderFinished] = React.useState(false)
  const [deliverymanPosition, setDeliverymanPosition] = React.useState({ latitude: null, longitude: null })

  // Estado para o último step
  const [lastProgressStep, setLastProgressStep] = React.useState(null)
  const lastProgressStepRef = React.useRef(null)

  // Referências da rotina de rastreio do motoboy
  const MarkerRef = React.useRef(null)
  const MapRef = React.useRef(null)
  const ContentRef = React.useRef(null)

  const getDeliveryTimeRange = () => {
    const dateNow = new Date(content.dt_created_at)
    const dateRangeFirst = moment(dateNow).add(content.delivery_time, "minute")
    const dateRangeSecond = moment(dateRangeFirst).add(formatFloat(settings.site_tolerance_time), "minute").format("HH:mm")
    return `${moment().format("DD") === dateRangeFirst.format("DD") ? "Hoje" : "Amanhã"}, ${dateRangeFirst.format("HH:mm")} a ${dateRangeSecond}`
  }

  React.useEffect(() => {
    if (status && !!ContentRef.current) {
      if (!!socket && socket?.connected) {
        // Motoboy se movendo
        socket.on("deliveryman_moved", ({ user, location }) => {
          console.info("RECEBENDO DADOS DO SOCKET [OrderProgress] [deliveryman_moved]", { user, location })

          if (!!lastProgressStepRef.current) {
            if (
              [5].includes(lastProgressStepRef.current.master_step_id) ||
              (lastProgressStepRef.current.master_step_id === 3 && formatBoolean(settings.dispatch_order_ready))
            ) {
              if (user.id_user === lastProgressStepRef.current.order_in_progress.fk_id_user) {
                setDeliverymanPosition({ latitude: location.latitude, longitude: location.longitude })
              }
            }
          }
          // console.log("user", user)
          // console.log("location", location)
          //Verificando se o motoboy é vinculado a esta empresa
          const userCompany = user?.companies?.find(({ id_company }) => {
            return company.id_company === id_company
          })
          if (!!userCompany) {
            setCurrentStep({
              ...currentStep,
              deliverymanPos: {
                latitude: user.latitude,
                longitude: user.longitude,
              },
              userId: user.id_user,
              userOrdersInRoute: user.orders_in_route,
            })
          }
        })
      }

      if (!!ContentRef.current.customer_address_order) {
        const boxToFit = [
          [deliverymanPosition.longitude, deliverymanPosition.latitude],
          [ContentRef.current.customer_address_order.geo_coordinates[1], ContentRef.current.customer_address_order.geo_coordinates[0]],
        ]

        if (!!MapRef.current) {
          MapRef.current?.fitBounds(boxToFit, {
            padding: {
              bottom: 100,
              top: 100,
              right: 100,
              left: 100,
            },
          })
        }
      }
    } else {
      socket.removeListener("deliveryman_moved")
    }
  }, [status, ContentRef.current])

  React.useEffect(() => {
    if (!!content) {
      ContentRef.current = content

      if (!content.st_approved && content.order_progress.length === 1) {
        setCurrentProgressStep({
          step: 0,
          date: getFormatDate(content.order_progress[content.order_progress.length - 1].order_in_progress.initiate_at).getHour("hh:mm"),
        })
      }

      if (content.st_approved && content.order_progress.length >= 1) {
        if (
          content.order_progress[0].master_step_id === 5 ||
          (content.order_progress[0].master_step_id === 3 && formatBoolean(settings.dispatch_order_ready)) ||
          ([2, 3, 4].includes(content?.modality.id_modality) && content.order_progress[0]?.master_step_id >= 3)
        ) {
          setCurrentProgressStep({
            step: 2,
            date: getFormatDate(content.order_progress[0].order_in_progress.initiate_at).getHour("hh:mm"),
          })
        } else {
          setCurrentProgressStep({
            step: 1,
            date: getFormatDate(content.order_progress[0].order_in_progress.initiate_at).getHour("hh:mm"),
          })
        }

        const lastStep = content.order_progress?.sort((a, b) => b.step - a.step)[0]

        setLastProgressStep(lastStep)
        lastProgressStepRef.current = lastStep
      }

      if (!!content.location) {
        setDeliverymanPosition(content.location)

        const boxToFit = [
          [content.location.longitude, content.location.latitude],
          [ContentRef.current.customer_address_order.geo_coordinates[1], ContentRef.current.customer_address_order.geo_coordinates[0]],
        ]

        if (!!MapRef.current) {
          MapRef.current?.fitBounds(boxToFit, {
            padding: {
              bottom: 100,
              top: 100,
              right: 100,
              left: 100,
            },
          })
        }
      }
    }
  }, [content])

  React.useEffect(() => {
    if (lastProgressStep?.is_final_step) {
      setOrderFinished(true)
    } else {
      setOrderFinished(false)
    }
  }, [lastProgressStep])

  React.useEffect(() => {
    if (content?.id_order === currentStep.orderId) {
      const newDate = new Date()
      const hourDate = newDate.getHours()
      const minDate = newDate.getMinutes()
      setCurrentProgressStep({ ...currentStep, date: `${hourDate > 9 ? hourDate : "0" + hourDate}:${minDate > 9 ? minDate : "0" + minDate}` })
    }
  }, [currentStep])

  React.useEffect(() => {
    if (!!ContentRef.current && !!ContentRef.current.customer_address_order) {
      const boxToFit = [
        [deliverymanPosition.longitude, deliverymanPosition.latitude],
        [ContentRef.current.customer_address_order.geo_coordinates[1], ContentRef.current.customer_address_order.geo_coordinates[0]],
      ]

      if (!!MapRef.current) {
        MapRef.current?.fitBounds(boxToFit, {
          padding: {
            bottom: 100,
            top: 100,
            right: 100,
            left: 100,
          },
        })
      }
    }
  }, [deliverymanPosition])

  if (status && !!content) {
    return (
      <div className={"order-progress " + (status ? "order-progress-active" : "")}>
        <div className={"order-progress--header"}>
          <FontAwesomeIcon
            className="order-progress--header--return"
            icon={"chevron-left"}
            onClick={() => {
              returnMenu()
              setDeliverymanPosition({ latitude: null, longitude: null })
            }}
          />
          <span>Acompanhar Pedido</span>
        </div>
        {!deliverymanPosition.latitude && [5].includes(content.order_progress[0].master_step_id) && !orderFinished ? (
          <div className="order-progress--post-header">
            <Spinner />
            Carregando localização do entregador...
          </div>
        ) : (
          ""
        )}

        <Toaster />

        <div className="order-progress--body">
          <div className="order-progress--body--map">
            {content.modality.id_modality === 1 && (
              <div className="order-progress--body--bottom">
                {orderFinished ? (
                  <div className="order-progress--body--bottom-overlay finished">
                    <div className="order-progress--body--bottom-overlay--finished">
                      <i>
                        <FontAwesomeIcon icon="check" color="#fff" size="2xl" />
                      </i>
                      <h2 className="order-progress--body--bottom-overlay--finished-title">Pedido entregue!</h2>
                      <div className="order-progress--body--bottom-overlay--finished--infos">
                        <div className="order-progress--body--bottom-overlay--finished--infos--item">
                          <span className="order-progress--body--bottom-overlay--finished--infos--item-title">Realizado</span>
                          <span className="order-progress--body--bottom-overlay--finished--infos--item-subtitle">
                            {getFormatDate(content.order_progress[content.order_progress.length - 1].order_in_progress.initiate_at).getHour("hh:mm")}
                          </span>
                        </div>
                        <div className="order-progress--body--bottom-overlay--finished--infos--item">
                          <span className="order-progress--body--bottom-overlay--finished--infos--item-title">Entregue</span>
                          <span className="order-progress--body--bottom-overlay--finished--infos--item-subtitle">
                            {getFormatDate(content.order_progress[0].order_in_progress.finish_at).getHour("hh:mm")}
                          </span>
                        </div>
                        <div className="order-progress--body--bottom-overlay--finished--infos--item">
                          <span className="order-progress--body--bottom-overlay--finished--infos--item-title">Nº do pedido</span>
                          <span className="order-progress--body--bottom-overlay--finished--infos--item-subtitle">
                            {setNumber(content.nro_order, 3)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : content.order_progress[0].master_step_id === 5 ||
                  (content.order_progress[0].master_step_id === 3 && formatBoolean(settings.dispatch_order_ready)) ? (
                  <div className="order-progress--body--bottom-overlay minus" />
                ) : (
                  <div className="order-progress--body--bottom-overlay">
                    <h2>{content.st_approved ? "Seu pedido está sendo preparado" : "Aguardando confirmação do restaurante"}</h2>
                  </div>
                )}
                {([5].includes(content.order_progress[0].master_step_id) && !orderFinished) ||
                (content.order_progress[0].master_step_id >= 3 && formatBoolean(settings.dispatch_order_ready) && !orderFinished) ? (
                  // <MapsCF
                  //   initialViewState={{ longitude: deliverymanPosition.longitude, latitude: deliverymanPosition.latitude, zoom: 14 }}
                  //   deliverymanLocation={{ longitude: deliverymanPosition.longitude, latitude: deliverymanPosition.latitude }}
                  //   homeLocation={{ longitude: content.customer_address_order.geo_coordinates[1], latitude: content.customer_address_order.geo_coordinates[0] }}
                  //   mapLocation={null}
                  //   mapHandlers={false}
                  // />
                  <Map
                    mapLib={mapboxgl}
                    mapboxAccessToken="pk.eyJ1IjoiY2l0eWZvb2RzIiwiYSI6ImNsaHAza25uOTIxYWgzc3M2M2F0czJzYWYifQ.E0h9HVHIGM418FucrGy6Dg"
                    initialViewState={{
                      longitude: content.customer_address_order.geo_coordinates[1],
                      latitude: content.customer_address_order.geo_coordinates[0],
                      zoom: 14,
                    }}
                    dragRotate={false}
                    scrollZoom={false}
                    boxZoom={false}
                    interactive={false}
                    style={{ width: "100vw", height: "100vh" }}
                    padding={{ bottom: 340 }}
                    mapStyle="mapbox://styles/cityfoods/clkipsu4l00q401qkccrz6u2j"
                    ref={MapRef}
                  >
                    {!!deliverymanPosition.latitude && !orderFinished ? (
                      <Marker ref={MarkerRef} latitude={deliverymanPosition.latitude} longitude={deliverymanPosition.longitude} anchor="center">
                        <div className="order-progress--body--bottom-company-marker">
                          <img alt="Loja" src={deliverymanMarker} />
                        </div>
                      </Marker>
                    ) : (
                      <></>
                    )}

                    <Marker
                      latitude={content.customer_address_order.geo_coordinates[0]}
                      longitude={content.customer_address_order.geo_coordinates[1]}
                      anchor="center"
                    >
                      <div className="order-progress--body--bottom-home-marker">
                        <img alt="Loja" src={homeMarker} />
                      </div>
                    </Marker>
                  </Map>
                ) : (
                  <img className="order-progress--body--bottom-fake-map" src={FakeMap} alt="Mapa" />
                )}
              </div>
            )}
          </div>
          <div className={`order-progress--body--infos ${content.modality.id_modality === 1 && "fixed"} ${orderFinished && "finished"}`}>
            <div className="order-progress--body--top">
              <Label style={{ fontWeight: 600, fontSize: 12 }}>
                {content?.modality.id_modality === 1
                  ? "Previsão de Entrega"
                  : content?.modality.id_modality === 2
                  ? "Tempo estimado para retirada"
                  : "Tempo estimado para consumo no local"}
              </Label>
              <Label style={{ fontWeight: 600 }}>{getDeliveryTimeRange()}</Label>
            </div>
            <div className="order-progress--body--center">
              <VerticalTimeline layout={"1-column-left"}>
                <VerticalTimelineElement
                  className=""
                  date={currentProgressStep.step === 0 ? currentProgressStep.date : ""}
                  icon={
                    <FontAwesomeIcon
                      style={
                        currentProgressStep.step === 0
                          ? { width: 6, height: 6, color: "#FFFFFF", top: 12 }
                          : { width: 1, height: 1, color: "#D00505", top: 12 }
                      }
                      icon="circle"
                    />
                  }
                  iconStyle={{ alignItems: "flex-start" }}
                  contentStyle={{ top: -2, display: "flex", justifyContent: "space-between", paddingRight: 10 }}
                >
                  <Label style={currentProgressStep.step === 0 ? { fontWeight: 700 } : {}}>Aguardando confirmação do restaurante</Label>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                  className=""
                  date={currentProgressStep.step === 1 ? currentProgressStep.date : ""}
                  icon={
                    <FontAwesomeIcon
                      style={
                        currentProgressStep.step === 1
                          ? { width: 6, height: 6, color: "#FFFFFF", top: 12 }
                          : { width: 1, height: 1, color: "#D00505", top: 10 }
                      }
                      icon="circle"
                    />
                  }
                  iconStyle={{ alignItems: "center" }}
                  contentStyle={{ top: 5, display: "flex", justifyContent: "space-between", paddingRight: 10 }}
                >
                  <Label style={currentProgressStep.step === 1 ? { fontWeight: 700 } : {}}>Seu pedido está sendo preparado</Label>
                </VerticalTimelineElement>

                {currentProgressStep.step === 3 ? (
                  <VerticalTimelineElement
                    className=""
                    date={currentProgressStep.step === 3 ? currentProgressStep.date : ""}
                    icon={<FontAwesomeIcon style={{ width: 6, height: 6, color: "#FFFFFF", top: 6 }} icon="circle" />}
                    iconStyle={{ alignItems: "flex-end" }}
                    contentStyle={{ top: 10, display: "flex", justifyContent: "space-between", paddingRight: 10 }}
                  >
                    <Label style={{ fontWeight: 700 }}>Pedido finalizado!</Label>
                  </VerticalTimelineElement>
                ) : (
                  <VerticalTimelineElement
                    className=""
                    date={currentProgressStep.step === 2 ? currentProgressStep.date : ""}
                    icon={
                      <FontAwesomeIcon
                        style={
                          currentProgressStep.step === 2
                            ? { width: 6, height: 6, color: "#FFFFFF", top: 6 }
                            : { width: 1, height: 1, color: "#D00505", top: 4 }
                        }
                        icon="circle"
                      />
                    }
                    iconStyle={{ alignItems: "flex-end" }}
                    contentStyle={{ top: 10, display: "flex", justifyContent: "space-between", paddingRight: 10 }}
                  >
                    <Label style={currentProgressStep.step === 2 ? { fontWeight: 700 } : {}}>{`Seu pedido ${
                      content?.modality.id_modality === 1 ? "saiu para a entrega" : "está pronto"
                    }`}</Label>
                  </VerticalTimelineElement>
                )}
              </VerticalTimeline>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return ""
}

export default OrderProgress

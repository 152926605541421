import React from "react"

//External libs
// import Geocode from "react-geocode"
// import { getLatLng, geocodeByPlaceId } from "react-places-autocomplete"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

//Contexts
import SettingsContext from "../../../contexts/SettingsContext"
import CompanyContext from "../../../contexts/CompanyContext"
import MenuContext from "../../../contexts/MenuContext"
import OrderContext from "../../../contexts/OrderContext"
import UserContext from "../../../contexts/UserContext"

//Components
// import CFAddressHandler from "../../../components/CFAddressHandler/CFAddressHandler"
import { PlaceMap } from "../../../components/MapsCF"
import CFButton from "../../../components/CFButton/CFButton"

//
import DeliveryTypeSelectorTakeOut from "../../../components/DeliveryInfo/DeliveryTypeSelectorTakeOut"

//Helpers
import { setLocalItem } from "../../../helpers/StorageTools"

import "./ModalInitialAddress.scss"
import AddressesInfoItem from "../../../components/Profile/AddressesInfoItem"
import { formatBoolean } from "../../../helpers/GeneralTools"

export default function ModalInitialAddress({ status, content, onClose, onPlaceSelected, onShowMap, setModalMinimapAddress }) {
  const { company } = React.useContext(CompanyContext)
  const { setDeliveryType } = React.useContext(OrderContext)
  const { user, userSelectedAddress } = React.useContext(UserContext)
  const { modalInitialAddress, setModalSignIn } = React.useContext(MenuContext)
  const { onChangeUserSelectedAddress, setModality, modality, settings } = React.useContext(SettingsContext)

  const [tabSelected, setTabSelected] = React.useState("delivery")
  const [userAddresses, setUserAddresses] = React.useState([])
  const [selectedIdAddress, setSelectedIdAddress] = React.useState(null)
  const [orderToTakeOut, setOrderToTakeOut] = React.useState(modality === 2)
  const [nextButton, setNextButton] = React.useState({
    title: "Salvar",
  })
  const [previousButton, setPreviousButton] = React.useState({
    title: "Fechar",
  })
  const [showGetLocationButton, setShowGetLocationButton] = React.useState(false)
  const [consumeInEstablishment, setConsumeInEstablishment] = React.useState(false)

  React.useEffect(() => {
    if (status) {
      if (
        !!company?.company_modality?.find(({ fk_id_modality }) => fk_id_modality === 1) &&
        (content?.source === "newAddress" || ![3, 2].includes(modality))
      ) {
        setTabSelected("delivery")
      } else if (!!company?.company_modality?.find(({ fk_id_modality }) => fk_id_modality === 2) || [3, 2].includes(modality)) {
        setTabSelected("take-out")
      }

      if (!!content.modality) {
        if (modality === 1) {
          setTabSelected("delivery")
        } else {
          setTabSelected("take-out")
          setConsumeInEstablishment(modality === 3)
        }
      }
    }
  }, [status, content, modality])

  React.useEffect(() => {
    if (status) {
      let userAddresses = []
      if (!!user?.addresses) {
        const userActiveAddress = user.addresses.filter(({ customer_address }) => !customer_address.dt_deleted_at)
        if (!!userSelectedAddress && !userSelectedAddress.id_street) {
          userAddresses = [...userActiveAddress, userSelectedAddress]
        } else {
          userAddresses = [...userActiveAddress]
        }
      } else {
        if (!!userSelectedAddress) {
          userAddresses = [userSelectedAddress]
        }
      }
      setUserAddresses(userAddresses)
    }
  }, [user, status])

  const onClearModal = () => {
    setTabSelected("delivery")
    setUserAddresses([])
    setSelectedIdAddress(null)
    setOrderToTakeOut(modality === 2)
    setNextButton({
      title: "Salvar",
    })
    setPreviousButton({
      title: "Fechar",
    })
    setConsumeInEstablishment(false)
  }

  const handleNextButton = async () => {
    // Selecionado retirada
    if (tabSelected === "take-out") {
      if (consumeInEstablishment) {
        setModality(3)
      } else {
        setModality(2)
      }

      onClose()
      onClearModal()
    } else {
      setModality(0)
      onClose()
      onClearModal()
    }
  }

  const handlePreviusButton = () => {
    //Tela de escolher o tipo de escolha de endereço
    onClearModal()
    onClose()
  }

  return (
    <Modal
      isOpen={modalInitialAddress.status}
      className="modal-initial-address"
      toggle={() => {
        onClose()
        onClearModal()
      }}
      backdrop={"static"}
      fade={false}
      fullscreen
      // centered={true}
    >
      {content?.source !== "newAddress" ? (
        <ModalHeader className="modal-initial-address--header">
          {!!company?.company_modality?.find(({ fk_id_modality }) => fk_id_modality === 1) ? (
            <div
              className={"modal-initial-address--header--delivery" + (tabSelected === "delivery" ? " modality-selected" : "")}
              onClick={() => {
                setTabSelected("delivery")
                setDeliveryType("delivery")
              }}
            >
              <span>Entrega</span>
            </div>
          ) : (
            ""
          )}
          {!!company?.company_modality?.find(({ fk_id_modality }) => fk_id_modality === 2) ? (
            <div
              className={"modal-initial-address--header--take-out" + (tabSelected === "take-out" ? " modality-selected" : "")}
              onClick={() => {
                setTabSelected("take-out")
                setDeliveryType("take-out")
              }}
            >
              <span>Retirada {formatBoolean(settings?.show_local_consumation) && "e Consumo no local"}</span>
            </div>
          ) : (
            ""
          )}
        </ModalHeader>
      ) : (
        ""
      )}
      <ModalBody className="modal-initial-address--body">
        {tabSelected === "delivery" ? (
          <div className="modal-initial-address--body--options">
            {showGetLocationButton && (
              <>
                <div className="modal-initial-address--body--options--button">
                  <FontAwesomeIcon className="icon-marker" color="#656565" icon="location-arrow" />
                  <div
                    className="modal-initial-address--body--options--button--description"
                    onClick={() => setModalMinimapAddress({ status: true, content: null })}
                  >
                    <Label className="modal-initial-address--body--options--button--description--title"> Buscar localização </Label>
                    <Label className="modal-initial-address--body--options--button--description--subtitle">
                      {" "}
                      Não conseguiu encontrar seu endereço? Que tal liberar sua localiza
                    </Label>
                  </div>
                </div>

                {!user && <span>OU</span>}
              </>
            )}
            {!user ? (
              <span>
                <div className="modal-initial-address--body--options--button">
                  <FontAwesomeIcon className="icon-marker" color="#656565" icon="right-to-bracket" />
                  <div className="modal-initial-address--body--options--button--description" onClick={() => setModalSignIn({ status: true })}>
                    <Label className="modal-initial-address--body--options--button--description--title"> Entrar </Label>
                    <Label className="modal-initial-address--body--options--button--description--subtitle"> Selecionar endereço já cadastrado </Label>
                  </div>
                </div>

                <span>OU</span>
              </span>
            ) : (
              ""
            )}
            <Label className="modal-initial-address--body--options--button--description--title"> Digite seu endereço </Label>

            <PlaceMap onPlaceSelected={onPlaceSelected} showMap={onShowMap} setShowGetLocationButton={setShowGetLocationButton} />
            {!!userAddresses && (
              <div className="modal-initial-address--body--options--addresses">
                <div className="modal-initial-address--body--options--addresses--divider" />
                <div className="modal-initial-address--body--options--addresses--items">
                  {userAddresses.map((address, index) => {
                    return (
                      <AddressesInfoItem
                        selectThisAddress={() => {
                          setLocalItem("userSelectedAddress", address, true)
                          setModality(1)
                          onChangeUserSelectedAddress(address)
                          setSelectedIdAddress(address.id_street)
                          onClearModal()
                          onClose()
                        }}
                        selected={address.id_street === selectedIdAddress && [0, 1].includes(modality)}
                        key={`${address.id_street}_${index}`}
                        address={address}
                        index={`${address.id_street}_${index}`}
                        origin={"deliverytype"}
                      />
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        ) : (
          <DeliveryTypeSelectorTakeOut
            consumeInEstablishment={consumeInEstablishment}
            setConsumeInEstablishment={setConsumeInEstablishment}
            orderToTakeOut={orderToTakeOut}
            setOrderToTakeOut={setOrderToTakeOut}
          />
        )}
      </ModalBody>
      <ModalFooter className="modal-initial-address--footer">
        <div className={"modal-initial-address--footer--cancel " + (tabSelected !== "take-out" ? "button-close" : "")}>
          <CFButton title={previousButton.title} onClick={handlePreviusButton} outline={true} />
        </div>

        {tabSelected === "take-out" ? (
          <div className="modal-initial-address--footer--confirm">
            <CFButton title={nextButton.title} onClick={handleNextButton} />
          </div>
        ) : (
          ""
        )}
      </ModalFooter>
    </Modal>
  )
}

import React from "react"
//External libs
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//Helpers
import {} from "../../helpers/AddressessTools"
import { formatBoolean } from "../../helpers/GeneralTools"
//Contexts
import SettingsContext from "../../contexts/SettingsContext"
import CompanyContext from "../../contexts/CompanyContext"
import UserContext from "../../contexts/UserContext"
import MenuContext from "../../contexts/MenuContext"
import "./DeliveryInfo.scss"
import { Label } from "reactstrap"

const DeliveryInfo = () => {
  const { modality } = React.useContext(SettingsContext)
  const { user, userSelectedAddress } = React.useContext(UserContext)
  const { setModalSignIn, setModalInitialAddress } = React.useContext(MenuContext)
  const { settings } = React.useContext(SettingsContext)

  const { company } = React.useContext(CompanyContext)

  const companyGeolocation = React.useRef(null)
  const companyAddress = company?.company_address?.find(({ st_primary }) => st_primary === true)

  if (!!companyAddress) {
    companyGeolocation.current = {
      latitude: companyAddress.geo_coordinates[0],
      longitude: companyAddress.geo_coordinates[1],
    }
  }
  return (
    <div
      className="delivery-info"
      onClick={() => {
        setModalInitialAddress({ status: true, content: { source: "orderPreview", modality } })
      }}
    >
      {modality === 3 ? (
        <div className="delivery-info--left">
          <FontAwesomeIcon icon={"cutlery"} />
        </div>
      ) : (
        <div className="delivery-info--left">
          <FontAwesomeIcon icon={"location-dot"} />
        </div>
      )}

      {/*Padrão*/}
      {(modality === 0 || (modality === 1 && !userSelectedAddress)) && <div className="delivery-info--center"> Escolha o endereço</div>}

      {/*Entrega*/}
      {modality === 1 && !!userSelectedAddress ? (
        <div className="delivery-info--center">
          <div className="delivery-info--center--upper">Entregar em</div>
          <div className="delivery-info--center--bottom">
            {(() => {
              const {
                nm_street,
                street_type: { nm_type },
                neighbourhood: {
                  nm_neighbourhood,
                  city: {
                    nm_city,
                    state: { ds_acronym },
                  },
                },
                customer_address: { ds_address_additional_info: complement, ds_observation: reference, num_address },
              } = userSelectedAddress

              return (
                <>
                  <Label>{`${nm_type} ${nm_street}` + `${!!num_address ? `, ${num_address}` : ""}` + `${complement ? `, ${complement}` : ""}`}</Label>{" "}
                  <Label> {`${nm_neighbourhood}, ${nm_city} - ${ds_acronym}`}</Label>
                  {reference ? <Label> {reference}</Label> : ""}
                </>
              )
            })()}
          </div>
        </div>
      ) : (
        ""
      )}

      {/*Retirada*/}
      {modality === 2 ? (
        <div className="delivery-info--center">
          <div className="delivery-info--center--upper">Retirar em</div>
          <div className="delivery-info--center--bottom">
            {companyAddress?.street?.nm_street}, {companyAddress?.num_address} - {companyAddress?.street?.neighbourhood?.city?.nm_city}
          </div>
        </div>
      ) : (
        ""
      )}

      {/*Consumo no local*/}
      {modality === 3 && formatBoolean(settings?.show_local_consumation) ? (
        <div className="delivery-info--center">
          <div className="delivery-info--center--upper">Consumir no local</div>
          <div className="delivery-info--center--bottom">
            {companyAddress?.street?.nm_street}, {companyAddress?.num_address} - {companyAddress?.street?.neighbourhood?.city?.nm_city}
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="delivery-info--right">
        <FontAwesomeIcon icon={"chevron-right"} />
      </div>
    </div>
  )
}

export default DeliveryInfo

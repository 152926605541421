import React from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import CFButton from "../../../components/CFButton/CFButton"
import { useGeocodingCore } from "@mapbox/search-js-react"

import { AddressMinimap } from "@mapbox/search-js-react"

import "./ModalMinimapAddress.scss"
import ModalNumberAddress from "../ModalNumberAddress/ModalNumberAddress"
import CompanyContext from "../../../contexts/CompanyContext"

const MAPBOX_KEY = process.env.REACT_APP_MAPBOX_KEY

const ModalMinimapAddress = ({ status, content, onClose, onConfirmLocation, setModalMinimapAddress }) => {
  const { company } = React.useContext(CompanyContext)

  const [modalAddressNumberModal, setModalAddressNumberModal] = React.useState({ status: false, content: null })

  const [locationSaved, setLocationSaved] = React.useState(null)
  const [mapLoading, setMapLoading] = React.useState(true)

  const [userLocation, setUserLocation] = React.useState({ latitude: null, longitude: null })
  const [locationError, setLocationError] = React.useState(null)
  const [freePin, setFreePin] = React.useState(false)

  const geocodingCore = useGeocodingCore({ accessToken: MAPBOX_KEY })

  React.useEffect(() => {
    if (status && !!content) {
      setTimeout(() => {
        const map = document.getElementsByTagName("mapbox-address-minimap")

        if (!!map) {
          const mapSeed = map[0].getAttribute("data-seed")

          const adjustButton = document.getElementsByClassName(`${mapSeed}--MinimapButtonAdjust`)
          const cancelButton = document.getElementsByClassName(`${mapSeed}--MinimapButtonCancel`)
          const saveButton = document.getElementsByClassName(`${mapSeed}--MinimapButtonSave`)
          const mapboxLogo = document.getElementsByClassName(`${mapSeed}--MinimapAttributionLogo`)
          const mapboxText = document.getElementsByClassName(`${mapSeed}--MinimapAttributionText`)
          const modalBody = document.getElementsByClassName(`modal-minimap-address--body`)
          const x = document.getElementsByClassName(`${mapSeed}--MinimapImageContainer`)
          const x2 = document.getElementsByClassName(`${mapSeed}--MinimapInnerFrame`)
          const x3 = document.getElementsByClassName(`${mapSeed}--MapboxAddressMinimap`)
          const x4 = document.getElementsByClassName(`${mapSeed}--MinimapImageContainer`)

          if (!!adjustButton) {
            adjustButton[0].style.display = "none"
            adjustButton[0].click()
          }

          if (!!cancelButton) {
            cancelButton[0].style.display = "none"
          }

          if (!!saveButton) {
            saveButton[0].style.display = "none"
          }

          if (!!mapboxLogo) {
            mapboxLogo[0].style.display = "none"
          }

          if (!!mapboxText) {
            mapboxText[0].style.display = "none"
          }
          if (!!x && !!modalBody) {
            x[0].style.width = `${map[0].getBoundingClientRect().width}px`
            x[0].style.height = `${modalBody[0].getBoundingClientRect().height - 32 - 42 - 20}px`
          }

          if (!!x3 && !!modalBody) {
            x3[0].style.width = `${map[0].getBoundingClientRect().width}px`
            x3[0].style.height = `${modalBody[0].getBoundingClientRect().height - 32 - 42 - 20}px`
          }

          if (!!x2) {
            // x2[0].style.marginTop = `94px`
            // x2[0].style.marginLeft = `16px`
            x2[0].style.width = `${map[0].getBoundingClientRect().width}px`
            x2[0].style.height = `${modalBody[0].getBoundingClientRect().height - 32 - 42 - 20}px`
          }

          // if (!!x4) {
          //   x2[0].style.display = `relative`
          // }

          setMapLoading(false)
        }
      }, 100)
    } else if (status && !content) {
      setMapLoading(false)
    }
  }, [status, content, freePin])

  React.useEffect(() => {
    if (!!locationSaved) {
      let addressNumber = null

      if (!!content.feature.properties.context.address) {
        const streetData = getStreetData(
          content.feature,
          locationSaved[1],
          locationSaved[0],
          content.feature.properties.context.address.address_number
        )

        onSaveLocation(streetData)
      } else {
        setModalAddressNumberModal({
          status: true,
          content: {
            num_address: "",
            label: `${content.feature.properties.context.street.name}, ${
              !!content.feature.properties.context.neighborhood ? content.feature.properties.context.neighborhood.name + ", " : ""
            } ${content.feature.properties.context.place.name} - ${content.feature.properties.context.region.name}`,
            feature: content,
          },
        })
      }
    }
  }, [locationSaved])

  React.useEffect(async () => {
    if (!!userLocation.latitude && !!userLocation.longitude) {
      const response = await geocodingCore.reverse(
        { lat: userLocation.latitude, lon: userLocation.longitude },
        {
          country: "BR",
          limit: 3,
          language: "pt-br",
          proximity: [company?.company_address[0]?.geo_coordinates[1], company?.company_address[0]?.geo_coordinates[0]],
          types: ["street"],
        }
      )

      const filteredFeatures = response.features.filter(
        (item, index, self) => index === self.findIndex((t) => t.properties.name === item.properties.name)
      )

      if (!!response.features[0]) {
        setModalMinimapAddress({
          status: true,
          content: { latitude: userLocation.latitude, longitude: userLocation.longitude, feature: response.features[0] },
        })
      }
    }
  }, [userLocation])

  const getStreetData = (feature, latitude, longitude, number) => {
    return {
      street: feature?.properties?.context?.street?.name,
      neighbourhood: feature?.properties?.context?.neighborhood?.name,
      zipcode:
        feature?.properties?.context?.postcode?.name.length === 9
          ? content?.feature?.properties?.context?.postcode?.name
          : content?.feature?.properties?.context?.postcode?.name + "-000",
      city: feature?.properties?.context?.place?.name,
      state: feature?.properties?.context?.region?.name,
      coordinates: { latitude, longitude },
      number: number,
    }
  }

  const onConfirmNumber = (number, { feature, latitude, longitude }) => {
    setModalAddressNumberModal({ status: false, content: null })

    const streetData = getStreetData(feature, latitude, longitude, number)

    onSaveLocation(streetData)
  }

  const onSaveLocation = (streetData) => {
    onConfirmLocation(streetData)
    onClose()
  }

  const getUserLocation = () => {
    setMapLoading(true)

    if ("geolocation" in navigator) {
      setMapLoading(true)

      navigator.geolocation.getCurrentPosition(
        async function (position) {
          setUserLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude })
        },
        (error) => {
          if (error.code === 1) {
            setLocationError("Permissão de localização negada, por favor permitir que o seu navegador acesse a sua localização.")
            setMapLoading(false)
          } else if (error.code === 2) {
            setLocationError("Infelizmente sua posição nesse momento está indisponível.")
            setMapLoading(false)
          } else {
            setLocationError("Não conseguimos identificar sua localização")
            setMapLoading(false)
          }
        }
      )
    } else {
      console.warn("Localização não disponível")

      setLocationError("Infelizmente não conseguimos encontrar sua localização.")
      setMapLoading(false)
    }
  }

  if (status) {
    return (
      <Modal
        className="modal-minimap-address"
        isOpen={status}
        toggle={() => {
          onClose()
          setFreePin(false)
        }}
        backdrop={"static"}
        fade={false}
        fullscreen={true}
        keyboard={false}
      >
        <div className={`modal-minimap-address--overlay ${freePin ? "show" : ""}`}>
          <p>Arraste o marcador para ajustar a localização. Você pode mover o mapa e o marcador para encontrar o endereço correto.</p>
        </div>
        <ModalHeader className="modal-minimap-address--header">{!!content ? "Ajustar localização" : "Liberar localização"}</ModalHeader>
        <ModalBody className="modal-minimap-address--body" onDragStart={(e) => console.log(e)} onDragEnd={(e) => console.log(e)}>
          <div className="modal-minimap-address--body--top">
            {!!content ? (
              <p>
                Marque no mapa onde você encontrará o entregador.
                <br />
                Ex: Portão ou entrada do condomínio.
              </p>
            ) : (
              <p>Libere sua localização para podermos encontrar seu endereço!</p>
            )}
          </div>
          {!!content ? (
            <div className="modal-minimap-address--body--map">
              {mapLoading && (
                <div className="modal-minimap-address--body--map--overlay">
                  <Spinner />
                  <p>Carregando o mapa</p>
                </div>
              )}
              {freePin ? (
                <>
                  <AddressMinimap
                    adjustBtnText="Ajustar localização"
                    cancelBtnText="Cancelar"
                    saveBtnText="Salvar localização"
                    accessToken={MAPBOX_KEY}
                    show={true}
                    footer={false}
                    canAdjustMarker={true}
                    onSaveMarkerLocation={(e) => {
                      setLocationSaved(e)
                    }}
                    feature={content.feature}
                    keepMarkerCentered={false}
                    theme={{
                      variables: {
                        colorPrimary: "#D00505",
                      },
                    }}
                  />
                </>
              ) : (
                <AddressMinimap
                  adjustBtnText="Ajustar localização"
                  cancelBtnText="Cancelar"
                  saveBtnText="Salvar localização"
                  accessToken={MAPBOX_KEY}
                  show={true}
                  footer={false}
                  canAdjustMarker={true}
                  onSaveMarkerLocation={(e) => {
                    setLocationSaved(e)
                  }}
                  feature={content.feature}
                  keepMarkerCentered={true}
                  theme={{
                    variables: {
                      colorPrimary: "#D00505",
                    },
                  }}
                />
              )}
            </div>
          ) : (
            <>
              <div className="modal-minimap-address--body--center">{locationError && <p className="">{locationError}</p>}</div>
              <CFButton
                title={!!locationError ? "Tentar novamente" : "Liberar localização"}
                icon="location"
                loading={mapLoading}
                loadingText="Carregando localização"
                onClick={() => {
                  getUserLocation()
                }}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter className="modal-minimap-address--footer">
          <div className="modal-minimap-address--footer--content">
            {!!content && (
              <CFButton title={freePin ? "Centralizar marcador" : "Liberar marcador"} onClick={() => setFreePin(!freePin)} outline={true} />
            )}
            <div className="modal-minimap-address--footer--flex">
              <CFButton
                title="Voltar"
                onClick={() => {
                  onClose()
                  setFreePin(false)
                }}
                outline={true}
              />
              {!!content && (
                <CFButton
                  title="Confirmar Localização"
                  onClick={() => {
                    if (!!content) {
                      const map = document.getElementsByTagName("mapbox-address-minimap")

                      if (!!map) {
                        const mapSeed = map[0].getAttribute("data-seed")

                        const saveButton = document.getElementsByClassName(`${mapSeed}--MinimapButtonSave`)
                        const adjustButton = document.getElementsByClassName(`${mapSeed}--MinimapButtonAdjust`)

                        if (!!saveButton) {
                          saveButton[0].click()
                        }

                        if (!!adjustButton) {
                          adjustButton[0].click()
                        }
                      }
                    }
                  }}
                />
              )}
            </div>
          </div>
        </ModalFooter>

        <ModalNumberAddress
          status={modalAddressNumberModal.status}
          content={modalAddressNumberModal.content}
          onConfirmNumber={onConfirmNumber}
          onClose={() => setModalAddressNumberModal({ status: false, content: null })}
        />
      </Modal>
    )
  }

  return ""
}

export default ModalMinimapAddress
